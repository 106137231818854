import { AxiosResponse } from 'axios';
import { baseApi } from './baseApi';

export const getOrganizationDetailsDataAPI = async (id: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/organization/${id}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateOrganizationDetailsAPI = async (id: string, data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.patch(`/dashboard/organization/${id}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDefaultValuesCreateOrganizationAPI = async (): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/organization/new/');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createOrganizationAPI = async (data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/organization/new/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};
