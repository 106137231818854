import { AxiosResponse } from 'axios';
import { baseApi } from './baseApi';

export const getAllSegmentsByOrganizationAPI = async (organizationId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/segments-list/${organizationId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNewSegmentAPI = async (organizationId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/segment-new/${organizationId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSegmentAPI = async (organizationId: string, segmentId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/segment-t/${organizationId}/${segmentId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createSegmentAPI = async (organizationId: string, data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.post(`/dashboard/segment-t/${organizationId}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateSegmentAPI = async (
  organizationId: string,
  segmentId: string,
  data: any
): Promise<AxiosResponse> => {
  try {
    return await baseApi.put(`/dashboard/segment-t/${organizationId}/${segmentId}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteSegmentAPI = async (organizationId: string, segmentId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.delete(`/dashboard/segment/${organizationId}/${segmentId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};
