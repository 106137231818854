import { AxiosResponse } from 'axios';

import { TRoles } from '../types';
import { baseApi } from './baseApi';

export const getTeamAPI = async (): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/team/');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllOrganizationsAPI = async (): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/team/organizations/');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const inviteParticipantAPI = async (data: { email: string }): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/team/participants/new/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteInviteAPI = async (userId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.delete(`/dashboard/team/invited/${userId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationParticipantsAPI = async (organizationId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/team/participants/${organizationId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateParticipantAPI = async (
  organizationId: string,
  data: { id: string; role: TRoles }
): Promise<AxiosResponse> => {
  try {
    return await baseApi.patch(`/dashboard/team/participants/${organizationId}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteParticipantAPI = async (userId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.delete(`/dashboard/team/participants/${userId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

interface IUpdateParticipantParams {
  organization_ids: string[];
  user_id: string;
}

export const updateParticipantOrganizationsAPI = async (data: IUpdateParticipantParams): Promise<AxiosResponse> => {
  try {
    return await baseApi.patch('/dashboard/team/participants/organizations/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

interface IDeleteParticipantParams {
  organization_id: string;
  user_id: string;
}

export const deleteParticipantFromOrganizationAPI = async (data: IDeleteParticipantParams): Promise<AxiosResponse> => {
  try {
    return await baseApi.delete('/dashboard/team/participants/organizations/', { data });
  } catch (error) {
    return Promise.reject(error);
  }
};
