import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

import { ITeam, TParticipantStatuses } from '../../types';
import { deleteInviteAPI } from '../../api';
import { ConfirmModal } from '../common';

interface IProps {
  participants: ITeam['invited'];
  onRevokeInvite?: () => void;
}
export const TeamPendingList: FC<IProps> = (props) => {
  const { participants, onRevokeInvite } = props;
  const { t } = useTranslation();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState<string>();

  const applyStatusBadge = useMemo(
    () => (status: TParticipantStatuses) => {
      switch (status) {
        case 'active':
          return <span className="badge bg-light-success">{t(status)}</span>;
        case 'invited':
          return <span className="badge bg-light-warning">{t(status)}</span>;
        case 'suspended':
          return <span className="badge bg-light-danger">{t(status)}</span>;
        case 'blocked':
          return <span className="badge bg-light-danger">{t(status)}</span>;
        case 'unpaid':
          return <span className="badge bg-light-danger">{t(status)}</span>;
      }
    },
    [t]
  );

  const openRevokeConfirmModal = (participantId: string) => {
    setSelectedParticipantId(participantId);
    setIsConfirmModalOpen(true);
  };

  const revokeInvite = async (participantId: string) => {
    try {
      setIsDeleting(true);

      const { data: inviteData } = await deleteInviteAPI(participantId);

      setIsConfirmModalOpen(false);

      onRevokeInvite && onRevokeInvite();
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="dt-responsive table-responsive mt-3">
      <h4>{t('pendingInvites')}</h4>

      <table id="user-list-table" className="table nowrap">
        <thead>
          <tr>
            <th>{t('member')}</th>
            <th>{t('inviteDate')}</th>
            <th>{t('status')}</th>
            <th className="text-right">{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {participants.map((participant) => (
            <tr key={participant.id}>
              <td className="align-middle">
                <div className="d-flex column-gap-2">
                  <div className="d-inline-block">
                    <p className="m-b-0">{participant.email}</p>
                  </div>
                </div>
              </td>
              <td className="align-middle">
                {participant.added_date ? new Intl.DateTimeFormat().format(new Date(participant.added_date)) : null}
              </td>
              <td className="align-middle">{applyStatusBadge(participant.status)}</td>
              <td className="align-middle">
                <div className="d-flex column-gap-2 align-items-center justify-content-end">
                  <button
                    type="button"
                    className="avtar avtar-xs btn-light-danger border-0"
                    onClick={() => openRevokeConfirmModal(participant.id)}
                  >
                    <FeatherIcon icon="trash-2" size={20} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isConfirmModalOpen ? (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title={t('confirmAction')}
          text={t('confirmDeletion')}
          onCancel={() => setIsConfirmModalOpen(false)}
          onConfirm={() => revokeInvite(selectedParticipantId!)}
          confirmText={isDeleting ? 'Deleting...' : 'Yes, delete it'}
          dangerConfirmButton
        />
      ) : null}
    </div>
  );
};
