import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { createSegmentAPI, getNewSegmentAPI } from '../../../api';
import { SegmentForm } from '../../../components/forms';

const OrganizationCreateSegment: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationId = '' } = useParams();

  const [organization, setOrganization] = useState<{ id: string; name: string }>();

  useEffect(() => {
    (async () => {
      try {
        if (!organizationId) {
          console.log('Organization ID is missing');
          return;
        }

        const { data: segmentData } = await getNewSegmentAPI(organizationId);

        setOrganization(segmentData.organization);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const createSegmentHandler = async (data: any) => {
    try {
      if (!organizationId) {
        console.log('Organization ID is missing');
        return;
      }

      const { data: segmentData } = await createSegmentAPI(organizationId, data);

      navigate(`/organization/${organizationId}/segments`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb breadcrumb-default-icon">
          <li className="breadcrumb-item">
            <Link to={`/organization/${organizationId}/segments`}>{t('segments')}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t('createNewSegment')}
          </li>
        </ol>
      </nav>

      <div className="page-header-title">
        <h3 className="m-b-10">{organization?.name}</h3>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('createSegment')}</h5>
          </div>
        </div>
        <div className="card-body">
          <SegmentForm onSuccessSubmit={createSegmentHandler} />
        </div>
      </div>
    </>
  );
};

export default OrganizationCreateSegment;
