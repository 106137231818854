/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { campaignsAPIGetCampaignsData, campaignsAPIGetCampaignsFilterData } from '../../api';
import Pagination from './common/Pagination';
import CampaignsTable from './common/CampaignsTable';
import FilterBlock from './common/FilterBlock';
import MobileCampaignsTable from './common/MobileCampaignsTable';
import Spinner from './common/Spinner';
import Csvonverter from './common/Csvonverter';

import 'react-datepicker/dist/react-datepicker.css';

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const t = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(t);
    };
  }, [value, delay]);
  return debouncedValue;
}

const Campaigns: React.FC = () => {
  const { t } = useTranslation();

  const [data, setData] = useState({
    count: 50,
    items: [],
    num_pages: 10,
    page: 1,
    per_page: 20,
  });

  const [filterQuery, setFilterQuery] = useState({
    page: data.page,
    per_page: data.per_page,
    sort_by: 'desc',
  });

  const [error, setError] = useState<Error | null>(null);

  const [page, setPage] = useState(data.page);
  const nPages = data.num_pages;

  const [clearFilters, setClearFilters] = useState(false);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 1000);
  const [isMobile] = useState(window.innerWidth < 991);
  const [entriesValue, setEntriesValue] = useState('20');
  const [sortBy, setSortBy] = useState('desc');
  const [showDatePicker, setShowDatePicker] = useState(false);

  const filtersOnChange = useCallback(() => {
    (async () => {
      try {
        setLoading(true);

        const { data: campaignsData } = await campaignsAPIGetCampaignsFilterData(filterQuery);

        setData(campaignsData);
      } catch (e) {
        setError(e as Error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [error, filterQuery]);

  useEffect(() => {
    //onData();
    setClearFilters(false);
  }, []);

  const filterQueryes = useCallback(
    (query: any) => {
      return Object.assign(filterQuery, query);
    },
    [filterQuery]
  );

  const deleteQueryes = useCallback(
    (query: any) => {
      for (const key in filterQuery) {
        if (key === query) {
          delete filterQuery[key as keyof typeof filterQuery];
        }
      }
    },
    [filterQuery]
  );

  const onSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    setSearchText(target.value);
  };

  const onEntries = useCallback(
    (value: string | number) => {
      filterQueryes({ per_page: value });
      setClearFilters(true);
      setEntriesValue(value as string);
      filtersOnChange();
      if (value === '20') {
        setClearFilters(false);
      }
    },
    [filterQueryes, filtersOnChange]
  );

  const onSearch = useCallback(
    (value: string) => {
      const search = { search: value };
      if (value === '') {
        deleteQueryes('search');
        filtersOnChange();
        setClearFilters(false);
      } else {
        filterQueryes(search);
        filtersOnChange();
        setClearFilters(true);
      }
    },
    [filterQueryes, filtersOnChange, setClearFilters, deleteQueryes]
  );

  useEffect(() => {
    if (debouncedSearch !== '') {
      onSearch(debouncedSearch);
    } else {
      onSearch('');
    }
  }, [debouncedSearch, onSearch, deleteQueryes, filtersOnChange, setClearFilters]);

  const onSort = useCallback(
    (value: string | number) => {
      setSortBy(value as string);
      filterQueryes({ sort_by: value });
      filtersOnChange();
      setClearFilters(true);
    },
    [filterQueryes, filtersOnChange, setClearFilters]
  );

  const onClearFilters = useCallback(() => {
    setSearchText('');
    setClearFilters(false);
    setFilterQuery({ page: 1, per_page: 20, sort_by: 'desc' });
    setPage(1);
    setEntriesValue('20');
    setShowDatePicker(false);
    setSortBy('desc');
    filtersOnChange();
  }, [filtersOnChange]);

  const onChangeData = useCallback((c: boolean) => {
    if (c) {
      (async () => {
        try {
          const { data: campaignsData } = await campaignsAPIGetCampaignsData();
          setData(campaignsData);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, []);

  const onPage = useCallback(
    (page: number) => {
      setPage(page);
      filterQueryes({ page });
      filtersOnChange();
    },
    [filtersOnChange, filterQueryes]
  );

  const onCalendar = useCallback(
    (show: boolean) => {
      setShowDatePicker(!show);
      deleteQueryes('from_date');
      deleteQueryes('to_date');
      filtersOnChange();
    },
    [deleteQueryes, filtersOnChange]
  );

  return (
    <div className="main-body">
      <div className="page-wrapper">
        <div className="page-header-title">
          <h3 className="m-b-10">{t('campaigns')}</h3>
        </div>

        <div className="card">
          <div className="card-block adaptive-table">
            <FilterBlock
              filterQuery={filterQuery}
              filtersOnChange={filtersOnChange}
              setClearFilters={setClearFilters}
              onSearchText={onSearchText}
              searchText={searchText}
              onEntries={onEntries}
              entriesValue={entriesValue}
              onCalendar={onCalendar}
              showPicker={showDatePicker}
              onSort={onSort}
              sortBy={sortBy}
            />

            <div
              className={`row ${clearFilters ? 'justify-content-between' : 'justify-content-end'} align-items-center mt-4`}
            >
              {clearFilters ? (
                <div className="col-auto">
                  <button type="button" className="btn btn-sm btn-outline-primary" onClick={onClearFilters}>
                    Clear filters
                  </button>
                </div>
              ) : null}

              <div className="col-auto">
                <Csvonverter fileName="campaigns" items={data.items} />
              </div>
            </div>

            {loading ? <Spinner /> : null}

            {data.items.length === 0 ? (
              <p className="text-center">{t('noResults')}</p>
            ) : (
              <>
                {!loading ? (
                  isMobile ? (
                    <MobileCampaignsTable data={data.items} onChange={onChangeData} />
                  ) : (
                    <CampaignsTable data={data.items} onChange={onChangeData} />
                  )
                ) : null}
                {!loading ? <Pagination total={nPages} page={page} onChange={onPage} /> : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Campaigns;
