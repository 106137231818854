/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

import { IOrganization, ITeam } from '../../types';
import { dashboardAPIGetCompaniesData, dashboardAPIGetData, dashboardAPIPostEmailData } from '../../api/dashboard';
import Spinner from './common/Spinner';

interface IDashboard {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  country_code: string;
  country: string;
  status: string;
  email_preferences?: { whats_new: boolean; best_practices: boolean };
  require_phone_number_verification: boolean;
  support_token: string;
  team?: ITeam;
}

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(false);
  const [data, setData] = useState<IDashboard>();
  const [organizationData, setOrganizationData] = useState<{
    items: IOrganization[];
    count: 10;
  }>();
  const [whatsNew, setWhatsNew] = useState(data?.email_preferences?.whats_new);
  const [bestPractices, setBestPractices] = useState(data?.email_preferences?.best_practices);

  const fetchDashboardData = useCallback(() => {
    (async () => {
      try {
        const [dashboardResponse, organizationsResponse] = await Promise.all<[any, any]>([
          dashboardAPIGetData(),
          dashboardAPIGetCompaniesData(),
        ]);

        const { data: dashboardData } = dashboardResponse;
        const { data: organizationsData } = organizationsResponse;

        if (dashboardData.status === 'active') {
          setData(dashboardData);
          setOrganizationData(organizationsData);
          setWhatsNew(dashboardData.email_preferences.whats_new);
          setBestPractices(dashboardData.email_preferences.best_practices);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const onNew = useCallback(() => {
    setWhatsNew(!whatsNew);
  }, [whatsNew]);

  const onBest = useCallback(() => {
    setBestPractices(!bestPractices);
  }, [bestPractices]);

  const onSubmit = useCallback(() => {
    (async () => {
      try {
        const data = {
          email_preferences: {
            whats_new: whatsNew,
            best_practices: bestPractices,
          },
        };
        const { data: emailResponse } = await dashboardAPIPostEmailData(data);

        if (emailResponse.status === 'active') {
          setBanner(true);
          setTimeout(() => {
            setBanner(false);
          }, 4000);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(true);
      }
    })();
  }, [whatsNew, bestPractices]);

  return (
    <div>
      <div className="main-body">
        <div className="page-wrapper">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5>{t('organizations')}</h5>
                <Link className="btn btn-primary" to="/organization/create">
                  <div className="d-flex align-items-center">
                    <FeatherIcon className="mr-2" icon="plus-circle" />
                    {t('addOrganization')}
                  </div>
                </Link>
              </div>
            </div>
            {!loading ? <Spinner top={10} /> : null}
            <div className="title-row d-flex align-items-center">
              <span className="empty-check m-r-10" />
              <div>{t('senderName')}</div>
              <div>{t('status')}</div>
              <div>{t('details')}</div>
              <div style={{ textAlign: 'right', paddingRight: 20 }}>{t('actions')}</div>
            </div>
            <div className="card-block task-details" style={{ paddingTop: 0 }}>
              {organizationData?.items.map((item) => (
                <div
                  key={item.id}
                  className="table-row d-flex align-items-center cell justify-content-between"
                  style={{ padding: 10, cursor: 'pointer' }}
                >
                  <div className="cell">{item.name}</div>
                  <div className="cell">{t(item.status)}</div>
                  <div className="align-items-center cell justify-content-left d-flex ">
                    <Link className="btn btn-primary" to={`/organization-details/${item.id}`}>
                      {t('view')}
                    </Link>
                  </div>
                  <div className="align-items-center cell justify-content-end d-flex ">
                    <Link
                      to="/campaign/new"
                      className="btn btn-primary"
                      state={{
                        organizationData: item,
                      }}
                    >
                      {t('newCampaign')}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="page-header-title">
            <h3 className="m-b-10">{t('myAccount')}</h3>
          </div>
          <div>
            <div className="task-detail-right">
              <div className="card">
                <div className="card-header">
                  <h5>{t('licenseInformation')}</h5>
                </div>
                <div className="card-block task-details">
                  <table className="table">
                    {data?.status ? (
                      <tbody>
                        <tr>
                          <td>
                            <FeatherIcon className="feather m-r-5 m-t-3m" icon="bar-chart-2" size={16} />
                            {t('accountStatus')}:
                          </td>
                          <td className="text-right">{t(data?.status)}</td>
                        </tr>
                      </tbody>
                    ) : null}
                  </table>
                  <div className="table-separator"></div>
                  <table className="table">
                    <tbody>
                      {data?.first_name || data?.last_name ? (
                        <tr>
                          <td>
                            <FeatherIcon className="feather m-r-5 m-t-3m" icon="user" size={14} /> {t('name')}:
                          </td>
                          <td className="text-right">
                            {data?.first_name ? <span>{data?.first_name}</span> : null}{' '}
                            {data?.last_name ? <span>{data?.last_name}</span> : null}
                          </td>
                        </tr>
                      ) : null}
                      {data?.phone_number ? (
                        <tr>
                          <td>
                            <FeatherIcon className="feather m-r-5 m-t-3m" icon="phone" size={14} /> {t('phoneNumber')}:
                          </td>
                          <td className="text-right">
                            {data.phone_number}
                            {/* <div>
                              <div className="font-weight-bold">
                                Verify phone number and receive 25 free lookup requests
                              </div>
                              <button className="btn btn-success mt-2 px-4">Verify</button>
                            </div> */}
                          </td>
                        </tr>
                      ) : null}
                      {data?.country ? (
                        <tr>
                          <td>
                            <FeatherIcon className="feather m-r-5 m-t-3m" icon="globe" size={14} />{' '}
                            {t('accountCountry')}:
                          </td>
                          <td className="text-right">{data?.country}</td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-12">
                      <p>
                        {t('companyInfoText_short')} <a href="#">{t('companyInfoLink')}</a>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data?.email_preferences ? (
              <form className="task-detail-right">
                <div className="card">
                  <div className="card-header">
                    <h5>{t('emailPreferencesTitle')}</h5>
                  </div>
                  <div className="card-block task-details">
                    <div>
                      <div className="checkbox checkbox-primary d-inline">
                        <input type="checkbox" name="whats_new" id="whats_new" checked={whatsNew} onChange={onNew} />
                        <label htmlFor="whats_new" className="cr mb-0">
                          <b>{t('emailPreferencesOptions.whats_new')}</b>
                        </label>
                      </div>
                      <div className="checkbox checkbox-primary d-inline">
                        <input
                          type="checkbox"
                          name="best_practices"
                          id="best_practices"
                          checked={bestPractices}
                          onChange={onBest}
                        />
                        <label htmlFor="best_practices" className="cr mb-0">
                          <b>{t('emailPreferencesOptions.best_practices')}</b>
                        </label>
                      </div>
                      <button type="button" onClick={onSubmit} className="btn btn-primary mt-4" disabled={false}>
                        {banner ? t('successSaving') : t('emailPreferencesOptions.submit')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            ) : null}
          </div>
          <div></div>
        </div>
        {/* <div className="modals-container"></div> */}
      </div>
    </div>
  );
};
export default Dashboard;
