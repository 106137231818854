/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { authAPIPostPasswordVerifySignIn } from '../../api';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import AuthLayout from '../../components/layout/AuthLayout';
import { LangDropdown } from '../../components/common';
import { firebaseLogInEvent } from '../../lib/analytics/firebaseEvents';

const LoginPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: { email },
  } = useLocation();

  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const { onLogIn } = useAuthContext();

  const timeZoneFormatted = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const onRestorePassword = useCallback(() => {
    navigate('/login-code-password-restore', {
      state: {
        email,
      },
    });
  }, [navigate, email]);

  const handlePasswword = useCallback((event: any) => {
    const value = event.target.value;
    setPassword(value.trim());
  }, []);

  const onInput = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      (async () => {
        if (!loading) {
          try {
            setLoading(true);
            const request = {
              password: password,
              contact: email,
              timezone: timeZoneFormatted,
            };

            const { data: verifySignInResponse } = await authAPIPostPasswordVerifySignIn(request);

            if (verifySignInResponse.success && verifySignInResponse.session_token) {
              onLogIn(verifySignInResponse.session_token, verifySignInResponse.expire_date);
              firebaseLogInEvent({ method: 'dashboard' });

              if (verifySignInResponse.need_password) {
                navigate('/add-password');
              } else {
                navigate('/dashboard');
              }
            }
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        }
      })();
    },
    [password, timeZoneFormatted, email, navigate, onLogIn, loading, t]
  );

  return (
    <>
      <AuthLayout>
        <h4 className="text-center f-w-500 m-t-25">{t('Login')}</h4>
        <p className="m-b-5 text-center">{t('passwordDescription')}</p>
        <form onSubmit={onInput} className="form-group mb-3 t-a-l">
          <label htmlFor="password" className="m-b-5">
            {t('password')}
          </label>
          <input
            maxLength={128}
            type="password"
            className="form-control "
            id="password"
            onChange={handlePasswword}
            required
            autoFocus
          />
          <small className="form-text text-muted"> {t('forms.signUp.errors.1086')}</small>
          <div className="text-center m-t-25 m-b-15">
            <button type="submit" className="btn btn-primary shadow px-sm-4" disabled={password === '' || loading}>
              {t('logIn')}
            </button>
          </div>
        </form>
        <div className="d-flex justify-content-center align-items-center m-b-25">
          <span onClick={onRestorePassword} className="link choices__item--selectable">
            {t('forms.restorePassword.title')}
          </span>
        </div>
        <LangDropdown />
      </AuthLayout>
    </>
  );
};
export default LoginPassword;
