/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { authAPIPostOtpVerifySignIn } from '../../api';
import AuthLayout from '../../components/layout/AuthLayout';
import { useAuthContext } from '../../providers/auth/hooks/useAuthContext';
import { LangDropdown } from '../../components/common';
import LoginCodeLayout from './LoginCodeLayout';

const LoginCodeSignIn: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const [error, setError] = useState<Error | null>(null);
  const [banner, setBanner] = useState(false);
  const [loading, setLoading] = useState(false);

  const { onLogIn } = useAuthContext();

  const {
    state: { email },
  } = useLocation();

  const handleCode = useCallback((c: string) => {
    setCode(c);
  }, []);

  const onInput = useCallback(
    (e: SyntheticEvent) => {
      (async () => {
        if (!loading) {
          try {
            e.preventDefault();
            setLoading(true);
            const request = {
              otp: code,
              contact: email as string,
            };

            const { data: verifySignInResponse } = await authAPIPostOtpVerifySignIn(request);

            if (verifySignInResponse.session_token) {
              onLogIn(verifySignInResponse.session_token, verifySignInResponse.expire_date);

              if (verifySignInResponse.need_password) {
                navigate('/add-password');
              } else {
                navigate('/dashboard');
              }
            } else {
              setBanner(true);
            }
          } catch (e) {
            setBanner(true);
            console.log(error);
            setError(e as Error);
          } finally {
            setLoading(false);
          }
        }
      })();
    },
    [code, navigate, error, email, onLogIn, loading]
  );

  return (
    <AuthLayout>
      <h4 className="text-center f-w-500 m-t-25">{t('Login')}</h4>
      <p className="m-b-5 text-center">{t('codeDescription')}</p>
      <form onSubmit={onInput} className="form-group mb-3 t-a-l">
        <LoginCodeLayout handleCode={handleCode} banner={banner} loading={loading} />
      </form>
      <LangDropdown />
    </AuthLayout>
  );
};
export default LoginCodeSignIn;
