import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

import { ITeam, ITeamOrganization, TParticipantStatuses } from '../../types';
import { deleteParticipantAPI, updateParticipantOrganizationsAPI } from '../../api';
import { Avatar, ConfirmModal, Select } from '../common';

interface IProps {
  participants: ITeam['participants'];
  organizations: ITeamOrganization[];
  onDeleteParticipant?: () => void;
}
export const TeamList: FC<IProps> = (props) => {
  const { participants, organizations, onDeleteParticipant } = props;
  const { t } = useTranslation();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState<string>();

  const applyStatusBadge = useMemo(
    () => (status: TParticipantStatuses) => {
      switch (status) {
        case 'active':
          return <span className="badge bg-light-success">{t(status)}</span>;
        case 'invited':
          return <span className="badge bg-light-warning">{t(status)}</span>;
        case 'suspended':
          return <span className="badge bg-light-danger">{t(status)}</span>;
        case 'blocked':
          return <span className="badge bg-light-danger">{t(status)}</span>;
        case 'unpaid':
          return <span className="badge bg-light-danger">{t(status)}</span>;
      }
    },
    [t]
  );

  const openDeleteConfirmModal = (participantId: string) => {
    setSelectedParticipantId(participantId);
    setIsConfirmModalOpen(true);
  };

  const deleteParticipantFromTeam = async (participantId: string) => {
    try {
      setIsDeleting(true);

      await deleteParticipantAPI(participantId);

      setIsConfirmModalOpen(false);

      onDeleteParticipant && onDeleteParticipant();
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeleting(false);
    }
  };

  const changeOrganizationHandler = async (userId: string, organizations: any) => {
    const prepareIds = organizations.map((item: any) => item.value);

    try {
      await updateParticipantOrganizationsAPI({
        organization_ids: prepareIds,
        user_id: userId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const organizationOptions = useMemo(() => {
    return organizations.map((item) => ({ id: item.id, label: item.name, value: item.id }));
  }, [organizations.length]);

  return (
    <div className="dt-responsive table-responsive mt-3">
      <table id="user-list-table" className="table nowrap">
        <thead>
          <tr>
            <th>{t('member')}</th>
            <th>{t('organizations')}</th>
            <th>{t('inviteDate')}</th>
            <th>{t('status')}</th>
            <th className="text-right">{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {participants.map((participant) => (
            <tr key={participant.id}>
              <td className="align-middle">
                <div className="d-flex column-gap-2">
                  <Avatar
                    // imgSrc="https://flowbite-react.com/images/people/profile-picture-5.jpg"
                    initials={participant.first_name.charAt(0) + participant.last_name.charAt(0)}
                  />
                  <div className="d-inline-block">
                    <h6 className="m-b-0">{`${participant.first_name} ${participant.last_name}`}</h6>
                    <p className="m-b-0">{participant.email}</p>
                  </div>
                </div>
              </td>
              <td className="align-middle">
                {participant.organizations?.length && organizationOptions?.length ? (
                  <Select
                    options={organizationOptions}
                    initialValue={organizationOptions.filter((option) =>
                      participant.organizations?.some((org) => option.value === org.id)
                    )}
                    onChange={(organizations) => changeOrganizationHandler(participant.id, organizations)}
                    multiple
                  />
                ) : null}
              </td>
              <td className="align-middle">
                {participant.added_date ? new Intl.DateTimeFormat().format(new Date(participant.added_date)) : null}
              </td>
              <td className="align-middle">{applyStatusBadge(participant.status)}</td>
              <td className="align-middle">
                <div className="d-flex column-gap-2 align-items-center justify-content-end">
                  {!participant.owner ? (
                    <button
                      type="button"
                      className="avtar avtar-xs btn-light-danger border-0"
                      onClick={() => openDeleteConfirmModal(participant.id)}
                    >
                      <FeatherIcon icon="trash-2" size={20} />
                    </button>
                  ) : null}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isConfirmModalOpen ? (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          title={t('confirmAction')}
          text={t('confirmDeletion')}
          onCancel={() => setIsConfirmModalOpen(false)}
          onConfirm={() => deleteParticipantFromTeam(selectedParticipantId!)}
          confirmText={isDeleting ? 'Deleting...' : 'Yes, delete it'}
          dangerConfirmButton
        />
      ) : null}
    </div>
  );
};
