import { AxiosResponse } from 'axios';
import { baseApi } from './baseApi';

export const authAPIGetSignUpData = async (): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/auth/signup/');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authAPIGetSignInData = async (id: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/auth/signup/${id}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authAPIPutSignUpData = async (
  id: string,
  data: Record<string, string | number>
): Promise<AxiosResponse> => {
  try {
    return await baseApi.put(`/dashboard/auth/signup/${id}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authAPIPostSignUpData = async (data: Record<string, string | number>): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/auth/send/signup/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authAPIPostSignIn = async (data: Record<string, string | number>): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/auth/send/signin/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authAPIPostVerifyCode = async (data: Record<string, string | number>): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/auth/verify/signup/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authAPIPostAddPassword = async (
  data: Record<string, string | number | undefined>
): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/auth/add-password/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authAPIPostOtpVerifySignIn = async (
  data: Record<string, string | number | undefined>
): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/auth/verify/signin/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authAPIPostPasswordVerifySignIn = async (
  data: Record<string, string | number | undefined>
): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/auth/verify-password/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authAPIPostOtpRestorePassword = async (
  data: Record<string, string | number | undefined>
): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/auth/send/restore/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const authAPIPostRestorePassword = async (
  data: Record<string, string | number | undefined>
): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/auth/verify/restore/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};
