import { AxiosResponse } from 'axios';
import { baseApi } from './baseApi';

export const audienceAPIGetAudienceData = async (): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/audience-list/');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const audienceAPIGetAudienceDetailsData = async (id: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/audience/${id}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const audienceAPIDeleteContact = async (ids: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.delete('/dashboard/audience-list/', { data: ids });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const audienceAPIGetFilters = async (filterQuery: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/audience-list/?' + new URLSearchParams(filterQuery));
  } catch (error) {
    return Promise.reject(error);
  }
};
