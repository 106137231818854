/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface EntriesSelectProps {
  options: any[];
  onChange: (value: string | number, name?: string | undefined) => void;
  selectId?: string;
  id?: string[];
  name?: string;
  startValue?: string | null;
  isStatic?: boolean;
  noPadding?: boolean;
  numeric?: boolean;
  required?: boolean;
  effect?: boolean;
  timeZones?: any;
  startTime?: string;
  endTime?: string;
}

const CommonSelect: React.FC<EntriesSelectProps> = ({
  options,
  onChange,
  selectId = undefined,
  id,
  name = undefined,
  isStatic,
  noPadding,
  startValue,
  numeric,
  required,
  timeZones,
  effect,
  startTime,
  endTime,
}) => {
  const { t } = useTranslation();

  const onValue = useCallback(
    (event: any) => {
      if (id) {
        onChange(id[0], event.target.value);
      } else if (timeZones) {
        const t = timeZones.map((t: any) => {
          if (t.id === event.target.value) {
            return t.name;
          }
        });
        onChange(event.target.value, t);
      } else {
        onChange(event.target.value);
      }
    },
    [onChange, id, timeZones]
  );
  return (
    <div>
      <select id={selectId} name={name} className="form-control" onChange={onValue} required={required} value={startValue as string}>
        {startValue && !isStatic ? (
          <>
            <option value={startValue}>
              {startValue === 'icloud'
                ? t('icloud')
                : startValue === 'noEffect'
                  ? t('noEffect')
                  : timeZones
                    ? startValue
                    : startValue.charAt(0).toUpperCase() + startValue.slice(1)}
            </option>
            {!effect && !timeZones && <option value="">{t('select')}</option>}
          </>
        ) : !isStatic ? (
          <option value="select">{t('select')}</option>
        ) : null}
        {startTime ? <option value={startTime}>{startTime}</option> : null}
        {endTime ? <option value={endTime}>{endTime}</option> : null}
        {options
          .filter((o) => o.title !== startValue)
          .map((o, i) => {
            return o.title !== '' ? (
              <option key={i} value={o.value ? o.value : o}>
                {o.title}
              </option>
            ) : null;
          })}
      </select>
    </div>
  );
};

export default CommonSelect;
