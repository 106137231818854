/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { SyntheticEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { authAPIPostOtpRestorePassword } from '../../api';
import RegistrationAlert from '../../components/errors/RegistrationAlert';
import AuthLayout from '../../components/layout/AuthLayout';
import { LangDropdown } from '../../components/common';

const LoginEmailPasswordRestore: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleLoginEmail = () => navigate('/login-email');

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [hide, setHide] = useState(true);
  const [message, setMessage] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const emailAddress = useCallback(
    (event: any) => {
      const value = event.target.value;
      const EMAIL_REGEXP = /^\S+@\S+\.\S+$/;
      if (EMAIL_REGEXP.test(value)) {
        setValidEmail(true);
        setEmail(value.trim());
      } else {
        setValidEmail(false);
        setEmail(value.trim());
      }
      if (!value || value === '') {
        setHide(true);
        setMessage(false);
      }
    },
    [setHide]
  );

  const onUpdateEmail = useCallback(() => {
    if (email != '') {
      setHide(true);
    }
    if (!validEmail && email != '') {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [validEmail, email]);

  const onInput = useCallback(
    (e: SyntheticEvent) => {
      (async () => {
        if (!validEmail) {
          setHide(false);
        } else {
          setHide(true);
        }
        if (!loading) {
          try {
            e.preventDefault();
            setLoading(true);
            if (validEmail) {
              const data = {
                contact: email,
              };

              const { data: restorePasswordResponse } = await authAPIPostOtpRestorePassword(data);
              if (restorePasswordResponse.success) {
                if (restorePasswordResponse.authorization_type === 1) {
                  navigate('/login-code-password-restore', {
                    state: {
                      email,
                    },
                  });
                }
              }

              if (!restorePasswordResponse.success && restorePasswordResponse.code === 140) {
                setMessage(true);
              }
            } else {
              console.log('Validation failed');
            }
          } catch (e) {
            setError(e as Error);
            console.log(error);
          } finally {
            setLoading(false);
          }
        }
      })();
    },
    [validEmail, email, navigate, error, loading]
  );

  return (
    <AuthLayout>
      <h4 className="text-center f-w-500 m-t-25">{t('forms.restorePassword.title')}</h4>
      <p className="form-text text-muted text-center mb-3">{t('forms.restorePassword.description')}</p>
      <form onSubmit={onInput} className="form-group mb-3 t-a-l">
        <label htmlFor="floatingInput" className="m-b-5">
          {t('signinMessage')}
        </label>
        <input
          type="email"
          className={`form-control ${!hide && 'invalid'} `}
          id="floatingInput"
          onChange={emailAddress}
          required
          onBlur={onUpdateEmail}
        />
        {!validEmail && !hide ? <RegistrationAlert text={t('errors.general.550')} /> : null}
        {message ? <RegistrationAlert text={t('errors.wrongAccount')} /> : null}
        <div className="text-center m-t-25 m-b-15">
          <button type="submit" className="btn btn-primary shadow px-sm-4" disabled={!validEmail || message || loading}>
            {t('continue')}
          </button>
        </div>
      </form>
      <div className="d-flex justify-content-center align-items-center m-b-25">
        <span onClick={handleLoginEmail} className="link m-l-5 choices__item--selectable">
          {t('restorePasswordBackLoginProposal')}
        </span>
      </div>
      <LangDropdown />
    </AuthLayout>
  );
};
export default LoginEmailPasswordRestore;
