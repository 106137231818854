/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { campaignsAPIDeleteCampaignData } from '../../../api/campaigns';
import AudienceDetailsModal from '../modal/AudienceDetailsModal';

interface ItemProps {
  id: string;
  name: string;
  status: string;
  onDetails: (name: string, status: string, id: string, date: any) => void;
  onDeleteItems: (id: string, check: boolean) => void;
  createDate: Date;
}
const Item: React.FC<ItemProps> = ({ id, name, createDate, onDeleteItems, onDetails, status }) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  const checkBoxOnChange = useCallback(
    (id: string) => {
      if (id && !checked) {
        onDeleteItems(id, true);
        setChecked(true);
      } else {
        setChecked(false);
        onDeleteItems(id, false);
      }
    },
    [checked, onDeleteItems]
  );

  return (
    <>
      <tr style={{ borderBottom: 'none' }}>
        <td>
          <input
            onChange={() => checkBoxOnChange(id)}
            className="form-check-input input-primary m-t-2m m-r-10"
            type="checkbox"
            id="customCheckc1"
            checked={checked}
          />
        </td>
      </tr>

      <tr onClick={() => onDetails}>
        <td className="sentMessageTable-recipient" data-label={t('name')}>
          {name}
        </td>
        <td className="sentMessageTable-textWrapper d-none d-md-block" data-label={t('status')}>
          <span className="sentMessageTable-text"> {status}</span>
        </td>
        <td className="d-block d-md-none" data-label={t('recipient')}>
          <span className="sentMessageTable-text"> {id}</span>
        </td>
        <td className="d-block d-md-none" data-label={t('optInDate')}>
          {DateTime.fromISO(`${createDate}`).toLocaleString({
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </td>
      </tr>
    </>
  );
};

interface MessagesTableProps {
  data: any;
  onChange: (c: boolean) => void;
}

const MobileCampaignsTable: React.FC<MessagesTableProps> = ({ data, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const [deletedItems, setDeletedItems] = useState<any[]>([]);
  const [error, setError] = useState<Error | null>(null);

  const onOpenModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onDetails = useCallback((name: string, status: string, id: string, date: any) => {
    const data = {
      name,
      status,
      id,
      date,
    };
    setDetailsData(data);
    setIsOpen(true);
  }, []);

  const onDeleteItems = useCallback(
    (id: string, check: boolean) => {
      if (id && check) {
        const items = [...deletedItems, id];
        const uniqueNames = new Set(items);
        setDeletedItems(Array.from(uniqueNames));
      } else if (id && !check) {
        const newArray = deletedItems.filter((i) => {
          return i !== id;
        });
        setDeletedItems(newArray);
      }
    },
    [deletedItems]
  );

  const onDeleteItem = useCallback(() => {
    if (deletedItems) {
      (async () => {
        try {
          const { data: campaignsData } = await campaignsAPIDeleteCampaignData({ ids: deletedItems });
          onChange(true);
        } catch (e) {
          setError(e as Error);
          console.log(error);
        }
      })();
    }
  }, [error, deletedItems, onChange]);

  return (
    <>
      {deletedItems.length !== 0 ? (
        <div className="d-flex m-b-10" style={{ position: 'sticky', top: 100 }}>
          <div
            style={{ backgroundColor: '#FFF' }}
            className="btn btn-sm btn-outline-primary d-flex justify-content-center align-items-center"
            onClick={onDeleteItem}
          >
            <div className="d-flex justify-content-center align-items-center">Delete items?</div>
          </div>
        </div>
      ) : null}
      <table className="table table-striped table-hover" style={{ width: '100%' }}>
        <tbody>
          {data.map((i: any, index: number) => {
            return (
              <Item
                id={i.id}
                status={i.status}
                name={i.name}
                key={index}
                onDeleteItems={onDeleteItems}
                createDate={i.create_date}
                onDetails={() => onDetails(i.name, i.status, i.id, i.createDate)}
              />
            );
          })}
        </tbody>
      </table>
      {isOpen ? <AudienceDetailsModal onOpen={onOpenModal} isOpen={isOpen} data={detailsData} /> : null}
    </>
  );
};
export default MobileCampaignsTable;
