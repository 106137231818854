import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_PUBLISHABLE_KEY, WHITE_LABEL } from './config/environment';
import { AuthProvider } from './providers/auth/AuthProvider';
import { useAuthContext } from './providers/auth/hooks/useAuthContext';
import { Toaster } from './components/common/Toast';
import { MetaPixel } from './components/common';
import DashboardLayoyt from './components/dashboard/DashboardLayoyt';
import AddPassword from './pages/auth/AddPassword';
import LoginCodePasswordRestore from './pages/auth/LoginCodePasswordRestore';
import LoginCodeSignIn from './pages/auth/LoginCodeSignIn';
import LoginCodeSignUp from './pages/auth/LoginCodeSignUp';
import LoginEmail from './pages/auth/LoginEmail';
import LoginEmailPasswordRestore from './pages/auth/LoginEmailPasswordRestore';
import LoginPassword from './pages/auth/LoginPassword';
import PasswordRestoreAddPassword from './pages/auth/PasswordRestore';
import SignUp from './pages/auth/SignUp';
import PaymentMethodPage from './pages/billing/payment-method/PaymentMethodPage';
import CampaignDetails from './pages/dashboard/CampaignDetails';
import Audience from './pages/dashboard/Audience';
import Billing from './pages/dashboard/Billing';
import CreateCampaign from './pages/dashboard/CreateCampaign';
import Campaigns from './pages/dashboard/Campaigns';
import Dashboard from './pages/dashboard/Dashboard';
import EditCampaignDetails from './pages/dashboard/EditCampaignDetails';
import OrganizationCreatePage from './pages/organization/OrganizationCreatePage/OrganizationCreatePage';
import OrganizationDetails from './pages/dashboard/OrganizationDetails';
import Error404 from './pages/errors/Error404';
import SomethingWrong from './pages/errors/SomethingWrong';
import OrganizationSegments from './pages/organization/OrganizationSegments/OrganizationSegments';
import OrganizationCreateSegment from './pages/organization/OrganizationSegments/OrganizationCreateSegment';
import OrganizationEditSegment from './pages/organization/OrganizationSegments/OrganizationEditSegment';
import TeamOrganizationPage from './pages/team/TeamOrganizationPage/TeamOrganizationPage';
import TeamPage from './pages/team/TeamPage/TeamPage';
import OrganizationAdvertisingModalsPage from './pages/organization/OrganizationAdvertisingModal/OrganizationAdvertisingModalsPage';
import OrganizationAdvertisingModalBuilderPage from './pages/organization/OrganizationAdvertisingModal/OrganizationAdvertisingModalBuilderPage';
import OrganizationAdvertisingModalEditPage from './pages/organization/OrganizationAdvertisingModal/OrganizationAdvertisingModalEditPage';

let stripePromise = null;

if (!WHITE_LABEL) {
  stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
}

const Main = () => {
  const { isAuthorized } = useAuthContext();
  return (
    <Router>
      <MetaPixel />
      <Routes>
        {isAuthorized ? (
          <Route path="/" element={<DashboardLayoyt children={<Dashboard />} />} />
        ) : (
          <Route path="/" element={<LoginEmail />} />
        )}

        {isAuthorized ? null : <Route path="/login-email" element={<LoginEmail />} />}
        {isAuthorized ? null : <Route path="/add-password" element={<AddPassword />} />}
        {isAuthorized ? null : <Route path="/login-password" element={<LoginPassword />} />}
        {isAuthorized ? null : <Route path="/login-email-password-restore" element={<LoginEmailPasswordRestore />} />}
        {isAuthorized ? null : <Route path="/add-password-restore" element={<PasswordRestoreAddPassword />} />}
        {isAuthorized ? null : <Route path="/login-password" element={<LoginPassword />} />}
        {isAuthorized ? null : <Route path="/login-code-sign-up" element={<LoginCodeSignUp />} />}
        {isAuthorized ? null : <Route path="/login-code-sign-in" element={<LoginCodeSignIn />} />}
        {isAuthorized ? null : <Route path="/login-code-password-restore" element={<LoginCodePasswordRestore />} />}

        {isAuthorized ? <Route path="/dashboard" element={<DashboardLayoyt children={<Dashboard />} />} /> : null}
        {isAuthorized ? <Route path="/audience-list" element={<DashboardLayoyt children={<Audience />} />} /> : null}
        {isAuthorized ? <Route path="/campaigns" element={<DashboardLayoyt children={<Campaigns />} />} /> : null}
        {isAuthorized ? (
          <Route path="/campaign/new" element={<DashboardLayoyt children={<CreateCampaign />} />} />
        ) : null}
        {isAuthorized ? (
          <Route path="/campaign/:id" element={<DashboardLayoyt children={<CampaignDetails />} />} />
        ) : null}
        {isAuthorized ? (
          <Route path="/campaign/:id/edit" element={<DashboardLayoyt children={<EditCampaignDetails />} />} />
        ) : null}
        {isAuthorized ? <Route path="/team" element={<DashboardLayoyt children={<TeamPage />} />} /> : null}
        {isAuthorized ? (
          <Route path="/organization/create" element={<DashboardLayoyt children={<OrganizationCreatePage />} />} />
        ) : null}
        {isAuthorized ? (
          <Route path="/organization-details/:id" element={<DashboardLayoyt children={<OrganizationDetails />} />} />
        ) : null}
        {isAuthorized ? (
          <Route
            path="/team/organization/:organizationId"
            element={<DashboardLayoyt children={<TeamOrganizationPage />} />}
          />
        ) : null}
        {isAuthorized ? (
          <Route
            path="/organization/:organizationId/segments"
            element={<DashboardLayoyt children={<OrganizationSegments />} />}
          />
        ) : null}
        {isAuthorized ? (
          <Route
            path="/organization/:organizationId/segment/create"
            element={<DashboardLayoyt children={<OrganizationCreateSegment />} />}
          />
        ) : null}
        {isAuthorized ? (
          <Route
            path="/organization/:organizationId/segment/:segmentId/edit"
            element={<DashboardLayoyt children={<OrganizationEditSegment />} />}
          />
        ) : null}
        {isAuthorized ? (
          <Route
            path="/organization/:organizationId/advertising-modals"
            element={<DashboardLayoyt children={<OrganizationAdvertisingModalsPage />} />}
          />
        ) : null}
        {isAuthorized ? (
          <Route
            path="/organization/:organizationId/advertising-modal/builder"
            element={<DashboardLayoyt children={<OrganizationAdvertisingModalBuilderPage />} />}
          />
        ) : null}
        {isAuthorized ? (
          <Route
            path="/organization/:organizationId/advertising-modal/:modalId/edit"
            element={<DashboardLayoyt children={<OrganizationAdvertisingModalEditPage />} />}
          />
        ) : null}
        {!WHITE_LABEL && isAuthorized ? (
          <Route path="/billing/payment-method/paddle" element={<DashboardLayoyt children={<Billing />} />} />
        ) : null}
        {!WHITE_LABEL && isAuthorized ? (
          <Route
            path="/billing/payment-method"
            element={<DashboardLayoyt children={<PaymentMethodPage stripePromise={stripePromise} />} />}
          />
        ) : null}
        {isAuthorized ? (
          <Route path="/wrong-request" element={<DashboardLayoyt children={<SomethingWrong />} />} />
        ) : null}
        {isAuthorized ? null : <Route path="/sign-up" element={<SignUp />} />}

        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
};
function App() {
  return (
    <AuthProvider>
      <Main />
      <Toaster />
    </AuthProvider>
  );
}

export default App;
