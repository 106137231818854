/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';

import { campaignsAPIDeleteCampaignData } from '../../../api/campaigns';

interface CampaignsRowProps {
  id: string;
  onDeleteItems: (id: string, check: boolean) => void;
  campaignData: any;
}
const CampaignsRow: React.FC<CampaignsRowProps> = ({ id, onDeleteItems, campaignData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);

  const checkBoxOnChange = useCallback(
    (id: string) => {
      if (id && !checked) {
        onDeleteItems(id, true);
        setChecked(true);
      } else {
        setChecked(false);
        onDeleteItems(id, false);
      }
    },
    [checked, onDeleteItems]
  );

  const campaignId = campaignData.id;

  const onData = useCallback(() => {
    navigate(`/campaign/${campaignId}`, {
      state: {
        campaignId,
      },
    });
  }, [navigate, campaignId]);

  return (
    <div className="table-row d-flex align-items-center cell" style={{ padding: 10, cursor: 'pointer' }}>
      <input
        onChange={() => checkBoxOnChange(id)}
        className="form-check-input input-primary m-t-2m m-r-10"
        type="checkbox"
        id="customCheckc1"
        checked={checked}
      />
      <div className="d-flex align-items-center w-100" onClick={onData}>
        <div className="cell" style={{ flexBasis: '60%' }}>
          {campaignData.name}
        </div>
        <div className="cell" style={{ flexBasis: '20%' }}>
          {t(campaignData.status)}
        </div>
        <div className="cell" style={{ flexBasis: '20%' }}>
          {DateTime.fromISO(`${campaignData.create_date}`).toLocaleString({
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </div>
      </div>
    </div>
  );
};

interface CampaignsTableProps {
  data: any;
  onChange: (c: boolean) => void;
}

const CampaignsTable: React.FC<CampaignsTableProps> = ({ data, onChange }) => {
  const { t } = useTranslation();

  const [deletedItems, setDeletedItems] = useState<any[]>([]);
  const [error, setError] = useState<Error | null>(null);

  const onDeleteItems = useCallback(
    (id: string, check: boolean) => {
      if (id && check) {
        const items = [...deletedItems, id];
        const uniqueNames = new Set(items);
        setDeletedItems(Array.from(uniqueNames));
      } else if (id && !check) {
        const newArray = deletedItems.filter((i) => {
          return i !== id;
        });
        setDeletedItems(newArray);
      }
    },
    [deletedItems]
  );

  const onDeleteItem = useCallback(() => {
    if (deletedItems) {
      (async () => {
        try {
          const { data: campaignsData } = await campaignsAPIDeleteCampaignData({ ids: deletedItems });

          onChange(true);
          setDeletedItems([]);
        } catch (e) {
          setError(e as Error);
          console.log(error);
        }
      })();
    }
  }, [error, deletedItems, onChange]);

  return (
    <>
      <div className="card-block adaptive-table">
        {deletedItems.length !== 0 ? (
          <div className="d-flex m-b-10">
            <div
              className="btn btn-sm btn-outline-primary d-flex justify-content-center align-items-center"
              onClick={onDeleteItem}
            >
              <div className="d-flex justify-content-center align-items-center">Delete items?</div>
            </div>
          </div>
        ) : null}
        <div className="title-row d-flex align-items-center">
          <span className="empty-check m-r-10" />
          <div style={{ flexBasis: '60%' }}>{t('name')}</div>
          <div style={{ flexBasis: '20%' }}>{t('status')}</div>
          <div style={{ flexBasis: '20%' }}>{t('forms.billing.rows.dateCreate')}</div>
        </div>

        {data.map((item: any, index: number) => {
          return <CampaignsRow key={index} id={item.id} onDeleteItems={onDeleteItems} campaignData={item} />;
        })}
      </div>
    </>
  );
};
export default CampaignsTable;
