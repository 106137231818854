import { ChangeEvent, FC, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { flatten, safeParse as valibotSafeParse } from 'valibot';
import FeatherIcon from 'feather-icons-react';

import { getUnifiedFormData } from '../../../lib/getUnifiedFormData';
import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';
import { AdvertisingModalFormSchema } from '../../../schemes';
import {
  IAdvertisingModalContentSettings,
  IAdvertisingModalFrequencyCheckboxes,
  IAdvertisingModalFrequencySettings,
  IAdvertisingModalSettings,
  IAdvertisingModalStyleSettings,
  IAdvertisingModalTargetingCheckboxes,
  IAdvertisingModalTargetingSettings,
} from '../../../types';
import { useCollapsiblePanel } from '../../../hooks';
import { CollapsiblePanel, FormElementError } from '../../common';

interface IProps {
  initialValues: Partial<IAdvertisingModalSettings>;
  id?: string;
  onSubmit?: (data: Partial<IAdvertisingModalSettings>) => Promise<void>;
  onStyleSettingsChange?: (settings: IAdvertisingModalStyleSettings) => void;
  onContentSettingsChange?: (settings: IAdvertisingModalContentSettings) => void;
}

export const AdvertisingBuilderForm: FC<IProps> = (props) => {
  const {
    id = 'advertising-builder-form',
    onSubmit,
    initialValues,
    onStyleSettingsChange,
    onContentSettingsChange,
  } = props;
  const { token } = useAuthContext();
  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState<any>();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  // const [isFormSuccess, setIsFormSuccess] = useState<boolean>(false);

  const [isStylesShow, setIsStylesShow] = useState(true);
  const [isTargetingShow, setIsTargetingShow] = useState(false);
  const [isFrequencyShow, setIsFrequencyShow] = useState(false);

  const [contentSettings, setContentSettings] = useState<IAdvertisingModalContentSettings>(
    initialValues?.content || {
      title: '',
      subtitle: '',
      description: '',
      cta_text: 'Go to',
      dismiss_text: 'Cancel',
    }
  );

  const [styleSettings, setStyleSettings] = useState<IAdvertisingModalStyleSettings>(
    initialValues?.configuration || {
      common: {
        bdRadius: '8',
        bgColor: '#ffffff',
      },
      title: {
        textColor: '#000000',
        fontFamily: 'Courier New',
        fontSize: '28',
      },
      subtitle: {
        textColor: '#000000',
        fontFamily: 'Arial',
        fontSize: '18',
      },
      description: {
        textColor: '#828181',
        fontFamily: 'Arial',
        fontSize: '14',
      },
      ctaButton: {
        textColor: '#ffffff',
        fontFamily: 'Arial',
        fontSize: '16',
        bgColor: '#000000',
      },
      dismissButton: {
        textColor: '#000000',
        fontFamily: 'Arial',
        fontSize: '16',
        bgColor: '#ffffff',
      },
    }
  );

  const [targetingSettings, setTargetingSettings] = useState<IAdvertisingModalTargetingSettings>(
    initialValues?.targeting || {
      visitor_device: 'all',
      show_after_time_delay: 0,
      show_after_page_scroll: 0,
      show_after_visited_pages: 0,
      exit_intent: false,
    }
  );

  const [targetingCheckboxes, setTargetingCheckboxes] = useState<IAdvertisingModalTargetingCheckboxes>({
    show_after_time_delay_is_checked: !!initialValues?.targeting?.show_after_time_delay,
    show_after_page_scroll_is_checked: !!initialValues?.targeting?.show_after_page_scroll,
    show_after_visited_pages_is_checked: !!initialValues?.targeting?.show_after_visited_pages,
  });

  const [frequencySettings, setFrequencySettings] = useState<IAdvertisingModalFrequencySettings>(
    initialValues?.frequency || {
      show_again_days: 1,
      show_again_after_cta: false,
    }
  );

  // const [frequencyCheckboxes, setFrequencyCheckboxes] = useState<IAdvertisingModalFrequencyCheckboxes>({
  //   show_again_days_is_checked: !Number.isNaN(initialValues?.frequency?.show_again_days),
  // });

  const fontFamilyOptions = useMemo(
    () => [
      { value: 'Arial', label: 'Arial' },
      { value: 'Times New Roman', label: 'Times New Roman' },
      { value: 'Courier New', label: 'Courier New' },
    ],
    []
  );
  const fontSizeOptions = useMemo(
    () => [
      { value: '22', label: '22px' },
      { value: '24', label: '24px' },
      { value: '26', label: '26px' },
      { value: '28', label: '28px' },
      { value: '30', label: '30px' },
    ],
    []
  );

  const changeContentSettingsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const { name, value } = target;

    const [_, key] = name.split('.');

    const newSettings = {
      ...contentSettings,
      [key]: value,
    };

    setContentSettings(newSettings);
    onContentSettingsChange?.(newSettings);
  };

  const changeTargettingSettingsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const { name, value } = target;

    const [_, key] = name.split('.');

    const newSettings = {
      ...targetingSettings,
      [key]: value,
    };

    setTargetingSettings(newSettings);
  };

  const changeFrequencySettingsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const { name, value } = target;

    const [_, key] = name.split('.');

    const newSettings = {
      ...frequencySettings,
      [key]: value,
    };

    setFrequencySettings(newSettings);
  };

  const toggleTargetingSettings = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const { name } = target;

    if (targetingCheckboxes[name as keyof IAdvertisingModalTargetingCheckboxes]) {
      const targetingName = name.replace('_is_checked', '');

      setTargetingSettings((prev: IAdvertisingModalTargetingSettings) => ({
        ...prev,
        [targetingName]: 0,
      }));
    }

    setTargetingCheckboxes((prev: IAdvertisingModalTargetingCheckboxes) => ({
      ...prev,
      [name]: !prev[name as keyof IAdvertisingModalTargetingCheckboxes],
    }));
  };

  // const toggleFrequencySettings = (event: ChangeEvent<HTMLInputElement>) => {
  //   const target = event.target;
  //   const { name } = target;

  //   if (frequencyCheckboxes[name as keyof IAdvertisingModalFrequencyCheckboxes]) {
  //     const frequencyName = name.replace('_is_checked', '');

  //     setFrequencySettings((prev: IAdvertisingModalFrequencySettings) => ({
  //       ...prev,
  //       [frequencyName]: 0,
  //     }));
  //   }

  //   setFrequencyCheckboxes((prev: IAdvertisingModalFrequencyCheckboxes) => ({
  //     ...prev,
  //     [name]: !prev[name as keyof IAdvertisingModalFrequencyCheckboxes],
  //   }));
  // };

  const changeStyleSettingsHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const target = event.target;
    const { name, value } = target;

    const [_, category, style] = name.split('.');

    const newSettings: IAdvertisingModalStyleSettings = {
      ...styleSettings,
      [category]: { ...styleSettings[category as keyof typeof styleSettings], [style]: value },
    };

    setStyleSettings(newSettings);
    onStyleSettingsChange?.(newSettings);
  };

  const {
    handleClose: handleCloseCommonStyles,
    handleOpen: handleOpenCommonStyles,
    isAnimation: isCommonStylesAnimation,
    isOpen: isCommonStylesOpen,
  } = useCollapsiblePanel({ open: true });

  const handleCommonStyles = () => {
    isCommonStylesOpen ? handleCloseCommonStyles() : handleOpenCommonStyles();
  };

  const {
    handleClose: handleCloseTitleStyles,
    handleOpen: handleOpenTitleStyles,
    isAnimation: isTitleStylesAnimation,
    isOpen: isTitleStylesOpen,
  } = useCollapsiblePanel();

  const handleTitleStyles = () => {
    isTitleStylesOpen ? handleCloseTitleStyles() : handleOpenTitleStyles();
  };

  const {
    handleClose: handleCloseSubtitleStyles,
    handleOpen: handleOpenSubtitleStyles,
    isAnimation: isSubtitleStylesAnimation,
    isOpen: isSubtitleStylesOpen,
  } = useCollapsiblePanel();

  const handleSubtitleStyles = () => {
    isSubtitleStylesOpen ? handleCloseSubtitleStyles() : handleOpenSubtitleStyles();
  };

  const {
    handleClose: handleCloseDescriptionStyles,
    handleOpen: handleOpenDescriptionStyles,
    isAnimation: isDescriptionStylesAnimation,
    isOpen: isDescriptionStylesOpen,
  } = useCollapsiblePanel();

  const handleDescriptionStyles = () => {
    isDescriptionStylesOpen ? handleCloseDescriptionStyles() : handleOpenDescriptionStyles();
  };

  const {
    handleClose: handleCloseCtaButtonStyles,
    handleOpen: handleOpenCtaButtonStyles,
    isAnimation: isCtaButtonStylesAnimation,
    isOpen: isCtaButtonStylesOpen,
  } = useCollapsiblePanel();

  const handleCtaButtonStyles = () => {
    isCtaButtonStylesOpen ? handleCloseCtaButtonStyles() : handleOpenCtaButtonStyles();
  };

  const {
    handleClose: handleCloseCancelButtonStyles,
    handleOpen: handleOpenCancelButtonStyles,
    isAnimation: isCancelButtonStylesAnimation,
    isOpen: isCancelButtonStylesOpen,
  } = useCollapsiblePanel();

  const handleCancelButtonStyles = () => {
    isCancelButtonStylesOpen ? handleCloseCancelButtonStyles() : handleOpenCancelButtonStyles();
  };

  const submitFormHandler = async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;
    const submitter = e.nativeEvent.submitter as HTMLButtonElement;

    const parseFormData = getUnifiedFormData(target);

    // console.log('parseFormData', parseFormData);

    const validate = valibotSafeParse(AdvertisingModalFormSchema, parseFormData);

    // console.log('validate', validate);

    if (validate.success) {
      setFormErrors(undefined);

      try {
        if (!token) {
          console.error('You miss token');
          return;
        }

        setIsFormSubmitting(true);

        await onSubmit?.({
          ...validate.output,
          status: submitter.value as IAdvertisingModalSettings['status'],
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsFormSubmitting(false);
      }
    } else {
      const flattenErrors = flatten<typeof AdvertisingModalFormSchema>(validate.issues);
      setFormErrors(flattenErrors.nested);
      // console.log('formErrors', flattenErrors.nested);
    }
  };

  return (
    <form id={id} onSubmit={submitFormHandler}>
      <div className="mb-3">
        <label className="form-label" htmlFor="modal-name">
          {t('name')}:
        </label>
        <input
          id="modal-name"
          type="text"
          className={'form-control' + (formErrors?.name?.length ? ' is-invalid' : '')}
          name="name"
          // onChange={changeStyleSettingsHandler}
          placeholder="Modal name"
          defaultValue={initialValues?.name}
        />
        {/* <small className="form-text text-muted">{t('name')}</small> */}
        {formErrors?.name?.length ? <FormElementError text={formErrors.name[0] || ''} /> : null}
      </div>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <button className="btn w-100" type="button" onClick={() => setIsStylesShow(!isStylesShow)}>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-0">{t('styles')}</h5>
              <span style={{ transform: isStylesShow ? 'rotate(90deg)' : 'none' }}>
                <FeatherIcon icon="chevron-right" size={20} />
              </span>
            </div>
          </button>
        </div>
        <div className={isStylesShow ? '' : 'd-none'}>
          <CollapsiblePanel
            isOpen={isCommonStylesOpen}
            onToggle={handleCommonStyles}
            isAnimation={isCommonStylesAnimation}
            title={t('common')}
            className="mt-4"
          >
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-container-border-radius">
                    {t('borderRadius')}:
                  </label>
                  <input
                    id="modal-container-border-radius"
                    type="number"
                    className={
                      'form-control' + (formErrors?.['configuration.common.bdRadius']?.length ? ' is-invalid' : '')
                    }
                    name="configuration.common.bdRadius"
                    onChange={changeStyleSettingsHandler}
                    placeholder="Radius"
                    min={0}
                    defaultValue={styleSettings.common.bdRadius}
                  />
                  {/* <small className="form-text text-muted">{t('container_bg')}</small> */}
                  {formErrors?.['configuration.common.bdRadius']?.length ? (
                    <FormElementError text={formErrors['configuration.common.bdRadius'][0] || ''} />
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-container-bg">
                    {t('backgroundColor')}:
                  </label>
                  <input
                    id="modal-container-bg"
                    type="color"
                    className={
                      'wid-50 hei-50 p-0 border-0' +
                      (formErrors?.['configuration.common.bgColor']?.length ? ' is-invalid' : '')
                    }
                    name="configuration.common.bgColor"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.common.bgColor}
                  />
                  {formErrors?.['configuration.common.bgColor']?.length ? (
                    <FormElementError text={formErrors['configuration.common.bgColor'][0] || ''} />
                  ) : null}
                </div>
              </div>
            </div>
          </CollapsiblePanel>

          <CollapsiblePanel
            className="mt-4"
            isOpen={isTitleStylesAnimation}
            onToggle={handleTitleStyles}
            isAnimation={isTitleStylesAnimation}
            title={t('title')}
          >
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-title">
                    {t('text')}:
                  </label>
                  <input
                    id="modal-title"
                    type="text"
                    className={'form-control' + (formErrors?.['content.title']?.length ? ' is-invalid' : '')}
                    name="content.title"
                    onChange={changeContentSettingsHandler}
                    placeholder="Text"
                    defaultValue={contentSettings.title}
                  />
                  {/* <small className="form-text text-muted">{t('title')}</small> */}
                  {formErrors?.['content.title']?.length ? (
                    <FormElementError text={formErrors['content.title'][0] || ''} />
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-title-font-family">
                    {t('fontFamily')}:
                  </label>
                  <select
                    className={
                      'form-select' + (formErrors?.['configuration.title.fontFamily']?.length ? ' is-invalid' : '')
                    }
                    id="modal-title-font-family"
                    name="configuration.title.fontFamily"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.title.fontFamily}
                  >
                    {fontFamilyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-title-font-size">
                    {t('fontSize')}:
                  </label>
                  <select
                    className={
                      'form-select' + (formErrors?.['configuration.title.fontSize']?.length ? ' is-invalid' : '')
                    }
                    id="modal-title-font-size"
                    name="configuration.title.fontSize"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.title.fontSize}
                  >
                    {fontSizeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-title-color">
                    {t('textColor')}:
                  </label>
                  <input
                    id="modal-title-color"
                    type="color"
                    className={
                      'wid-50 hei-50 p-0 border-0' +
                      (formErrors?.['configuration.title.textColor']?.length ? ' is-invalid' : '')
                    }
                    name="configuration.title.textColor"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.title.textColor}
                  />
                  {/* <small className="form-text text-muted">{t('title_color')}</small> */}
                  {formErrors?.['configuration.title.textColor']?.length ? (
                    <FormElementError text={formErrors['configuration.title.textColor'][0] || ''} />
                  ) : null}
                </div>
              </div>
            </div>
          </CollapsiblePanel>

          <CollapsiblePanel
            className="mt-4"
            isOpen={isSubtitleStylesOpen}
            onToggle={handleSubtitleStyles}
            isAnimation={isSubtitleStylesAnimation}
            title={t('subtitle')}
          >
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-subtitle">
                    {t('text')}:
                  </label>
                  <input
                    id="modal-subtitle"
                    type="text"
                    className={'form-control' + (formErrors?.['content.subtitle']?.length ? ' is-invalid' : '')}
                    name="content.subtitle"
                    onChange={changeContentSettingsHandler}
                    placeholder="Text"
                    defaultValue={contentSettings.subtitle}
                  />
                  {formErrors?.['content.subtitle']?.length ? (
                    <FormElementError text={formErrors['content.subtitle'][0] || ''} />
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-subtitle-font-family">
                    {t('fontFamily')}:
                  </label>
                  <select
                    className={
                      'form-select' + (formErrors?.['configuration.subtitle.fontFamily']?.length ? ' is-invalid' : '')
                    }
                    id="modal-subtitle-font-family"
                    name="configuration.subtitle.fontFamily"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.subtitle.fontFamily}
                  >
                    {fontFamilyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-subtitle-font-size">
                    {t('fontSize')}:
                  </label>
                  <select
                    className={
                      'form-select' + (formErrors?.['configuration.subtitle.fontSize']?.length ? ' is-invalid' : '')
                    }
                    id="modal-subtitle-font-size"
                    name="configuration.subtitle.fontSize"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.subtitle.fontSize}
                  >
                    {fontSizeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-subtitle-color">
                    {t('textColor')}:
                  </label>
                  <input
                    id="modal-subtitle-color"
                    type="color"
                    className={
                      'wid-50 hei-50 p-0 border-0' +
                      (formErrors?.['configuration.subtitle.textColor']?.length ? ' is-invalid' : '')
                    }
                    name="configuration.subtitle.textColor"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.subtitle.textColor}
                  />
                  {formErrors?.['configuration.subtitle.textColor']?.length ? (
                    <FormElementError text={formErrors['configuration.subtitle.textColor'][0] || ''} />
                  ) : null}
                </div>
              </div>
            </div>
          </CollapsiblePanel>

          <CollapsiblePanel
            className="mt-4"
            isOpen={isDescriptionStylesOpen}
            onToggle={handleDescriptionStyles}
            isAnimation={isDescriptionStylesAnimation}
            title={t('description')}
          >
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-description">
                    {t('text')}:
                  </label>
                  <input
                    id="modal-description"
                    type="text"
                    className={'form-control' + (formErrors?.['content.description']?.length ? ' is-invalid' : '')}
                    name="content.description"
                    onChange={changeContentSettingsHandler}
                    placeholder="Text"
                    defaultValue={contentSettings.description}
                  />
                  {/* <small className="form-text text-muted">{t('description')}</small> */}
                  {formErrors?.['content.description']?.length ? (
                    <FormElementError text={formErrors['content.description'][0] || ''} />
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-description-font-family">
                    {t('fontFamily')}:
                  </label>
                  <select
                    className={
                      'form-select' +
                      (formErrors?.['configuration.description.fontFamily']?.length ? ' is-invalid' : '')
                    }
                    id="modal-description-font-family"
                    name="configuration.description.fontFamily"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.description.fontFamily}
                  >
                    {fontFamilyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-description-font-size">
                    {t('fontSize')}:
                  </label>
                  <select
                    className={
                      'form-select' + (formErrors?.['configuration.description.fontSize']?.length ? ' is-invalid' : '')
                    }
                    id="modal-description-font-size"
                    name="configuration.description.fontSize"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.description.fontSize}
                  >
                    {fontSizeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-description-color">
                    {t('textColor')}:
                  </label>
                  <input
                    id="modal-description-color"
                    type="color"
                    className={
                      'wid-50 hei-50 p-0 border-0' +
                      (formErrors?.['configuration.description.textColor']?.length ? ' is-invalid' : '')
                    }
                    name="configuration.description.textColor"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.description.textColor}
                  />
                  {/* <small className="form-text text-muted">{t('description.textColor')}</small> */}
                  {formErrors?.['configuration.description.textColor']?.length ? (
                    <FormElementError text={formErrors['configuration.description.textColor'][0] || ''} />
                  ) : null}
                </div>
              </div>
            </div>
          </CollapsiblePanel>

          <CollapsiblePanel
            className="mt-4"
            isOpen={isCtaButtonStylesOpen}
            onToggle={handleCtaButtonStyles}
            isAnimation={isCtaButtonStylesAnimation}
            title={t('ctaButton')}
          >
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-cta-button-text">
                    {t('text')}:
                  </label>
                  <input
                    id="modal-cta-button-text"
                    type="text"
                    className={'form-control' + (formErrors?.['content.cta_text']?.length ? ' is-invalid' : '')}
                    name="content.cta_text"
                    placeholder="Text"
                    onChange={changeContentSettingsHandler}
                    defaultValue={contentSettings.cta_text}
                  />
                  {/* <small className="form-text text-muted">{t('title')}</small> */}
                  {formErrors?.['content.cta_text']?.length ? (
                    <FormElementError text={formErrors['content.cta_text'][0] || ''} />
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-cta-button-font-family">
                    {t('fontFamily')}:
                  </label>
                  <select
                    className={
                      'form-select' + (formErrors?.['configuration.ctaButton.fontFamily']?.length ? ' is-invalid' : '')
                    }
                    id="modal-cta-button-font-family"
                    name="configuration.ctaButton.fontFamily"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.ctaButton.fontFamily}
                  >
                    {fontFamilyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-cta-button-font-size">
                    {t('fontSize')}:
                  </label>
                  <select
                    className={
                      'form-select' + (formErrors?.['configuration.ctaButton.fontSize']?.length ? ' is-invalid' : '')
                    }
                    id="modal-cta-button-font-size"
                    name="configuration.ctaButton.fontSize"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.ctaButton.fontSize}
                  >
                    {fontSizeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="modal-cta-button-text-color">
                        {t('textColor')}:
                      </label>
                      <input
                        id="modal-cta-button-text-color"
                        type="color"
                        className={
                          'wid-50 hei-50 p-0 border-0' +
                          (formErrors?.['configuration.ctaButton.textColor']?.length ? ' is-invalid' : '')
                        }
                        name="configuration.ctaButton.textColor"
                        onChange={changeStyleSettingsHandler}
                        defaultValue={styleSettings.ctaButton.textColor}
                      />
                      {formErrors?.['configuration.ctaButton.textColor']?.length ? (
                        <FormElementError text={formErrors['configuration.ctaButton.textColor'][0] || ''} />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="modal-cta-button-bg-color">
                        {t('backgroundColor')}:
                      </label>
                      <input
                        id="modal-cta-button-bg-color"
                        type="color"
                        className={
                          'wid-50 hei-50 p-0 border-0' +
                          (formErrors?.['configuration.ctaButton.bgColor']?.length ? ' is-invalid' : '')
                        }
                        name="configuration.ctaButton.bgColor"
                        onChange={changeStyleSettingsHandler}
                        defaultValue={styleSettings.ctaButton.bgColor}
                      />
                      {formErrors?.['configuration.ctaButton.bgColor']?.length ? (
                        <FormElementError text={formErrors['configuration.ctaButton.bgColor'][0] || ''} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CollapsiblePanel>

          <CollapsiblePanel
            className="mt-4"
            isOpen={isCancelButtonStylesOpen}
            onToggle={handleCancelButtonStyles}
            isAnimation={isCancelButtonStylesAnimation}
            title={t('cancelButton')}
          >
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-cancel-button-text">
                    {t('text')}:
                  </label>
                  <input
                    id="modal-cancel-button-text"
                    type="text"
                    className={'form-control' + (formErrors?.['content.dismiss_text']?.length ? ' is-invalid' : '')}
                    name="content.dismiss_text"
                    placeholder="Text"
                    onChange={changeContentSettingsHandler}
                    defaultValue={contentSettings.dismiss_text}
                  />
                  {formErrors?.['content.dismiss_text']?.length ? (
                    <FormElementError text={formErrors['content.dismiss_text'][0] || ''} />
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-cancel-button-font-family">
                    {t('fontFamily')}:
                  </label>
                  <select
                    className={
                      'form-select' +
                      (formErrors?.['configuration.dismissButton.fontFamily']?.length ? ' is-invalid' : '')
                    }
                    id="modal-cancel-button-font-family"
                    name="configuration.dismissButton.fontFamily"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.dismissButton.fontFamily}
                  >
                    {fontFamilyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <label className="form-label" htmlFor="modal-cancel-button-font-size">
                    {t('fontSize')}:
                  </label>
                  <select
                    className={
                      'form-select' +
                      (formErrors?.['configuration.dismissButton.fontSize']?.length ? ' is-invalid' : '')
                    }
                    id="modal-cancel-button-font-size"
                    name="configuration.dismissButton.fontSize"
                    onChange={changeStyleSettingsHandler}
                    defaultValue={styleSettings.dismissButton.fontSize}
                  >
                    {fontSizeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="modal-cancel-button-text-color">
                        {t('textColor')}:
                      </label>
                      <input
                        id="modal-cancel-button-text-color"
                        type="color"
                        className={
                          'wid-50 hei-50 p-0 border-0' +
                          (formErrors?.['configuration.dismissButton.textColor']?.length ? ' is-invalid' : '')
                        }
                        name="configuration.dismissButton.textColor"
                        onChange={changeStyleSettingsHandler}
                        defaultValue={styleSettings.dismissButton.textColor}
                      />
                      {/* <small className="form-text text-muted">{t('dismissButton.textColor')}</small> */}
                      {formErrors?.['configuration.dismissButton.textColor']?.length ? (
                        <FormElementError text={formErrors['configuration.dismissButton.textColor'][0] || ''} />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="modal-cancel-button-bg-color">
                        {t('backgroundColor')}:
                      </label>
                      <input
                        id="modal-cancel-button-bg-color"
                        type="color"
                        className={
                          'wid-50 hei-50 p-0 border-0' +
                          (formErrors?.['configuration.dismissButton.bgColor']?.length ? ' is-invalid' : '')
                        }
                        name="configuration.dismissButton.bgColor"
                        onChange={changeStyleSettingsHandler}
                        defaultValue={styleSettings.dismissButton.bgColor}
                      />
                      {formErrors?.['configuration.dismissButton.bgColor']?.length ? (
                        <FormElementError text={formErrors['configuration.dismissButton.bgColor'][0] || ''} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CollapsiblePanel>
        </div>
      </div>

      <hr className="hr" />

      <div>
        <div className="d-flex justify-content-between align-items-center">
          <button className="btn w-100" type="button" onClick={() => setIsTargetingShow(!isTargetingShow)}>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-0">{t('targeting')}</h5>
              <span style={{ transform: isTargetingShow ? 'rotate(90deg)' : 'none' }}>
                <FeatherIcon icon="chevron-right" size={20} />
              </span>
            </div>
          </button>
        </div>
        <div className={isTargetingShow ? '' : 'd-none'}>
          <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">{t('visitorDevice')}</h5>
                <p className="text-muted mb-0">Description</p>
              </div>
            </div>
            <div className="d-flex column-gap-3 mt-2">
              <div className="form-check">
                <label htmlFor="visitor-device-all" className="form-check-label d-inline-flex">
                  {t('all')}
                </label>
                <input
                  id="visitor-device-all"
                  className="form-check-input"
                  type="radio"
                  name="targeting.visitor_device"
                  value="all"
                  defaultChecked={targetingSettings.visitor_device === 'all'}
                />
              </div>
              <div className="form-check">
                <label htmlFor="visitor-device-desktop" className="form-check-label d-inline-flex">
                  {t('desktop')}
                </label>
                <input
                  id="visitor-device-desktop"
                  className="form-check-input"
                  type="radio"
                  name="targeting.visitor_device"
                  value="desktop"
                  defaultChecked={targetingSettings.visitor_device === 'desktop'}
                />
              </div>
              <div className="form-check">
                <label htmlFor="visitor-device-mobile" className="form-check-label d-inline-flex">
                  {t('mobile')}
                </label>
                <input
                  id="visitor-device-mobile"
                  className="form-check-input"
                  type="radio"
                  name="targeting.visitor_device"
                  value="mobile"
                  defaultChecked={targetingSettings.visitor_device === 'mobile'}
                />
              </div>
            </div>
          </div>
          <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">{t('showAfterXSeconds')}</h5>
                <p className="text-muted mb-0">Description</p>
              </div>
              <div className="form-check form-switch form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="show_after_time_delay_is_checked"
                  onChange={toggleTargetingSettings}
                  checked={targetingCheckboxes.show_after_time_delay_is_checked}
                />
              </div>
            </div>
            <div className={'mt-2' + (targetingCheckboxes.show_after_time_delay_is_checked ? '' : ' d-none')}>
              <div className="">
                <input
                  type="number"
                  className={
                    'form-control' + (formErrors?.['targeting.show_after_time_delay']?.length ? ' is-invalid' : '')
                  }
                  name="targeting.show_after_time_delay"
                  placeholder="5"
                  min={0}
                  onChange={changeTargettingSettingsHandler}
                  value={targetingSettings?.show_after_time_delay}
                />
                {formErrors?.['targeting.show_after_time_delay']?.length ? (
                  <FormElementError text={formErrors['targeting.show_after_time_delay'][0] || ''} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">{t('showAfter%Scroll')}</h5>
                <p className="text-muted mb-0">Description</p>
              </div>
              <div className="form-check form-switch form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="show_after_page_scroll_is_checked"
                  onChange={toggleTargetingSettings}
                  checked={targetingCheckboxes.show_after_page_scroll_is_checked}
                />
              </div>
            </div>
            <div className={'mt-2' + (targetingCheckboxes.show_after_page_scroll_is_checked ? '' : ' d-none')}>
              <div className="">
                <input
                  type="number"
                  className={
                    'form-control' + (formErrors?.['targeting.show_after_page_scroll']?.length ? ' is-invalid' : '')
                  }
                  name="targeting.show_after_page_scroll"
                  placeholder="50"
                  min={0}
                  onChange={changeTargettingSettingsHandler}
                  value={targetingSettings?.show_after_page_scroll}
                />
                {formErrors?.['targeting.show_after_page_scroll']?.length ? (
                  <FormElementError text={formErrors['targeting.show_after_page_scroll'][0] || ''} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">{t('showAfterCertainNumberOfPages')}</h5>
                <p className="text-muted mb-0">Description</p>
              </div>
              <div className="form-check form-switch form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="show_after_visited_pages_is_checked"
                  onChange={toggleTargetingSettings}
                  checked={targetingCheckboxes.show_after_visited_pages_is_checked}
                />
              </div>
            </div>
            <div className={'mt-2' + (targetingCheckboxes.show_after_visited_pages_is_checked ? '' : ' d-none')}>
              <div className="">
                <input
                  type="number"
                  className={
                    'form-control' + (formErrors?.['targeting.show_after_visited_pages']?.length ? ' is-invalid' : '')
                  }
                  name="targeting.show_after_visited_pages"
                  placeholder="3"
                  min={0}
                  onChange={changeTargettingSettingsHandler}
                  value={targetingSettings?.show_after_visited_pages}
                />
                {formErrors?.['targeting.show_after_visited_pages']?.length ? (
                  <FormElementError text={formErrors['targeting.show_after_visited_pages'][0] || ''} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">{t('exitIntent')}</h5>
                <p className="text-muted mb-0">Description</p>
              </div>
              <div className="form-check form-switch form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="targeting.exit_intent"
                  defaultChecked={targetingSettings.exit_intent}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="hr" />

      <div>
        <div className="d-flex justify-content-between align-items-center">
          <button className="btn w-100" type="button" onClick={() => setIsFrequencyShow(!isFrequencyShow)}>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-0">{t('frequency')}</h5>
              <span style={{ transform: isFrequencyShow ? 'rotate(90deg)' : 'none' }}>
                <FeatherIcon icon="chevron-right" size={20} />
              </span>
            </div>
          </button>
        </div>
        <div className={isFrequencyShow ? '' : 'd-none'}>
          <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">{t('showAgainAfterDays')}</h5>
                <p className="text-muted mb-0">Description</p>
              </div>
            </div>
            <div className="mt-2">
              <input
                type="number"
                className={'form-control' + (formErrors?.['frequency.show_again_days']?.length ? ' is-invalid' : '')}
                name="frequency.show_again_days"
                placeholder="1"
                min={0}
                onChange={changeFrequencySettingsHandler}
                value={frequencySettings?.show_again_days}
              />
              {formErrors?.['frequency.show_again_days']?.length ? (
                <FormElementError text={formErrors['frequency.show_again_days'][0] || ''} />
              ) : null}
            </div>
          </div>
          <div className="border p-3 mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="mb-0">{t('showAgainAfterCTAClicked')}</h5>
                <p className="text-muted mb-0">Description</p>
              </div>
              <div className="form-check form-switch form-check-reverse">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="frequency.show_again_after_cta"
                  defaultChecked={frequencySettings.show_again_after_cta}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 text-right">
        <button className="btn btn-secondary mr-2" type="submit" disabled={isFormSubmitting} value="draft">
          {isFormSubmitting ? t('sending') : t('draft')}
        </button>
        <button className="btn btn-primary" type="submit" disabled={isFormSubmitting} value="active">
          {isFormSubmitting ? t('sending') : t('publish')}
        </button>
      </div>
    </form>
  );
};
