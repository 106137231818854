import axios from 'axios';

import { API_ENDPOINT } from '../config/environment';
import { axiosApiErrorHandler } from '../lib/axiosApiErrorHandler';

export const baseApi = axios.create({
  baseURL: API_ENDPOINT,
});

baseApi.interceptors.request.use(
  (request) => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      request.headers['Authorization'] = `${authToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

baseApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    axiosApiErrorHandler(error);
    // if (error.response) {
    //   switch (error.response.status) {
    //     case 401:
    //       // 返回 401 清除token信息并跳转到登录页面
    //       store.commit(types.LOGOUT);
    //       router.replace({
    //         path: 'login',
    //         query: { redirect: router.currentRoute.fullPath },
    //       });
    //   }
    // }
    return Promise.reject(error.response.data);
  }
);
