/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { DateTime } from 'luxon';
import ReactDatePicker from 'react-datepicker';

import CommonSelect from './CommonSelect';

import 'react-datepicker/dist/react-datepicker.css';

interface FilterBlockProps {
  filtersOnChange: () => void;
  setClearFilters: (clear: boolean) => void;
  onSearchText: (event: ChangeEvent<HTMLInputElement>) => void;
  onEntries: (value: string | number) => void;
  onSort: (value: string | number) => void;
  onCalendar: (show: boolean) => void;
  filterQuery: any;
  entriesValue?: string;
  sortBy?: string;
  searchText: string;
  showPicker?: boolean;
}

const FilterBlock: React.FC<FilterBlockProps> = ({
  filtersOnChange,
  setClearFilters,
  filterQuery,
  onSearchText,
  onEntries,
  searchText,
  entriesValue,
  sortBy,
  onSort,
  onCalendar,
  showPicker,
}) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);

  const onShowCalendar = () => {
    setShowDatePicker(!showDatePicker);
    onCalendar(showDatePicker);
  };

  const filterQueryes = useCallback(
    (query: any) => {
      return Object.assign(filterQuery, query);
    },
    [filterQuery]
  );

  const searchTextHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onSearchText(event);
  };

  const onStartDate = useCallback(
    (value: Date) => {
      setStartDate(value);
      const startDate = { from_date: DateTime.fromJSDate(value).toFormat('yyyy-MM-dd') };
      filterQueryes(startDate);
      filtersOnChange();
      setClearFilters(true);
    },
    [filterQueryes, filtersOnChange, setClearFilters]
  );

  const onEndDate = useCallback(
    (value: Date) => {
      setEndDate(value);
      const endDate = { to_date: DateTime.fromJSDate(value).toFormat('yyyy-MM-dd') };
      filterQueryes(endDate);
      filtersOnChange();
      setClearFilters(true);
    },
    [filterQueryes, filtersOnChange, setClearFilters]
  );

  const sortOptions = useMemo(
    () => [
      { title: t('latestFirst'), value: 'desc' },
      { title: t('oldestFirst'), value: 'asc' },
    ],
    [t]
  );

  return (
    <div className="row justify-content-between row-gap-4">
      <div className="col-12 col-sm-6 col-lg-4">
        <div className="d-flex align-items-center gap-2">
          <p className="mb-0">{t('search')}</p>
          <div className="w-100">
            <input
              name="search"
              onChange={searchTextHandler}
              className="form-control"
              placeholder={t('searchInputTest')}
              value={searchText}
            />
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-6 col-lg-4">
        <div className="d-flex align-items-center gap-2">
          <p className="mb-0">{t('date')}</p>
          <div className="w-100">
            <div className="form-control datepicker">
              <div onClick={onShowCalendar} style={{ cursor: 'pointer' }}>
                <FeatherIcon className="feather m-r-5 m-t-3m" icon="calendar" size={16} />
              </div>
              {showPicker ? (
                <>
                  <ReactDatePicker
                    wrapperClassName="input"
                    selected={startDate as Date}
                    onChange={(date: Date) => onStartDate(date)}
                    maxDate={new Date()}
                    dateFormat="yyyy/dd/MM"
                  />
                  <ReactDatePicker
                    wrapperClassName="input-right"
                    selected={endDate as Date}
                    onChange={(date) => {
                      onEndDate(date as Date);
                    }}
                    maxDate={new Date()}
                    dateFormat="yyyy/dd/MM"
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="col-6 col-lg-auto">
        {/* <div className="row">
          <div className="col mb-4"> */}
        <div className="d-flex align-items-center gap-2">
          <p className="text-nowrap mb-0">{t('sortBy')}</p>
          <div className="w-100">
            <CommonSelect name="sort" onChange={onSort} options={sortOptions} isStatic startValue={sortBy} />
          </div>
        </div>
      </div>

      <div className="col-6 col-lg-auto">
        <div className="d-flex align-items-center gap-2">
          <p className="mb-0">{t('show')}</p>
          <div className="w-100">
            <CommonSelect
              name="entries"
              onChange={onEntries}
              options={[
                { value: 20, title: 20 },
                { value: 50, title: 50 },
                { value: 100, title: 100 },
                { value: 200, title: 200 },
                { value: 500, title: 500 },
                { value: 1000, title: 1000 },
                { value: 2000, title: 2000 },
                { value: 5000, title: 5000 },
              ]}
              startValue={entriesValue}
              isStatic
            />
          </div>
          {/* <p className="mb-0">{t('entries')}</p> */}
        </div>
      </div>

      {/* <div className="col mb-4">
        
      </div> */}
      {/* </div>
      </div> */}
    </div>
  );
};
export default FilterBlock;
