import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ITeamOrganization } from '../../../types';
import { getAllOrganizationsAPI } from '../../../api';
import { MyTeam } from '../../../components';

const TeamPage: FC = () => {
  const { t } = useTranslation();

  const [organizations, setOrganizations] = useState<ITeamOrganization[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const { data: organizationsData } = await getAllOrganizationsAPI();

        setOrganizations(organizationsData.items);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="page-header-title">
        <h2 className="h3 m-b-10">{t('team')}</h2>
      </div>

      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('organizations')}</h5>
          </div>
        </div>
        <div className="card-block">
          <div className="dt-responsive table-responsive mt-3">
            <table className="table nowrap">
              <thead>
                <tr>
                  <th>{t('name')}</th>
                  <th className="text-right">{t('details')}</th>
                </tr>
              </thead>
              <tbody>
                {organizations.length > 0 &&
                  organizations.map((org) => (
                    <tr key={org.id}>
                      <td className="align-middle">
                        <div className="d-flex column-gap-2">
                          <p className="m-b-0">{org.name}</p>
                        </div>
                      </td>
                      <td className="align-middle text-right">
                        <Link
                          type="button"
                          className="btn btn-primary"
                          to={`/team/organization/${org.id}`}
                          state={{ organizationName: org.name }}
                        >
                          {t('view')}
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <MyTeam />
    </div>
  );
};

export default TeamPage;
