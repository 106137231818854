/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { useTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { convertToCsv, downloadCsv } from '../../../utils/csvDownload';

type AudienceProps = {
  items: any;
  fileName: string;
};

const Csvonverter: React.FC<AudienceProps> = ({ items, fileName }) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="text-capitalize btn btn-outline-secondary"
      onClick={() => downloadCsv(fileName, convertToCsv(items))}
    >
      <FeatherIcon className="feather m-r-5 m-t-3m" icon="download" size={16} /> {t('exportCsv')}
    </button>
  );
};
export default Csvonverter;
