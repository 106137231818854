import type { InferOutput } from 'valibot';

import { pipe, object, optional, string, nonEmpty, transform, union, literal, variant, number } from 'valibot';

export const AdvertisingModalFormSchema = object({
  // facebook_url: union([literal(''), pipe(string(), url())]),
  // first_name: optional(pipe(string(), nonEmpty('Please enter your first name.'))),
  name: pipe(string(), nonEmpty('Please, enter the name.')),
  content: object({
    title: string(),
    subtitle: string(),
    description: string(),
    cta_text: string(),
    dismiss_text: string(),
  }),
  configuration: object({
    common: object({
      bdRadius: string(),
      bgColor: string(),
    }),
    title: object({
      // text: optional(string()),
      fontFamily: string(),
      fontSize: string(),
      textColor: string(),
    }),
    subtitle: object({
      // text: optional(string()),
      fontFamily: string(),
      fontSize: string(),
      textColor: string(),
    }),
    description: object({
      // text: optional(string()),
      fontFamily: string(),
      fontSize: string(),
      textColor: string(),
    }),
    ctaButton: object({
      // text: optional(string()),
      fontFamily: string(),
      fontSize: string(),
      textColor: string(),
      bgColor: string(),
    }),
    dismissButton: object({
      // text: optional(string()),
      fontFamily: string(),
      fontSize: string(),
      textColor: string(),
      bgColor: string(),
    }),
  }),
  targeting: object({
    visitor_device: union([literal('all'), literal('desktop'), literal('mobile')]),
    show_after_time_delay: optional(
      pipe(
        string(),
        transform((input) => Number(input))
      ),
      '0'
    ),
    show_after_page_scroll: optional(
      pipe(
        string(),
        transform((input) => Number(input))
      ),
      '0'
    ),
    show_after_visited_pages: optional(
      pipe(
        string(),
        transform((input) => Number(input))
      ),
      '0'
    ),
    // traffic_source: optional(string()),
    exit_intent: optional(
      pipe(
        string(),
        transform((input) => input === 'on')
      ),
      'false'
    ),
  }),
  frequency: object({
    show_again_days: optional(
      pipe(
        string(),
        transform((input) => Number(input))
      ),
      '1'
    ),
    show_again_after_cta: optional(
      pipe(
        string(),
        transform((input) => input === 'on')
      ),
      'false'
    ),
  }),
});

export type IAdvertisingModalForm = InferOutput<typeof AdvertisingModalFormSchema>;
