import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IOrganizationDefaultValues } from '../../../types';
import { FormElementError } from '../../common';
import { WelcomeUploader } from './WelcomeUploader/WelcomeUploader';
import { PostWelcomeUploader } from './PostWelcomeUploader/PostWelcomeUploader';

interface IProps {
  defaultValues?: Partial<Pick<IOrganizationDefaultValues, 'welcomeMessage' | 'postWelcomeMessage'>> | null;
  showSubmitBtn?: boolean;
  organizationId?: string;
}

type Ref = HTMLFormElement;

export const OrgWelcomeMessageForm = forwardRef<Ref, IProps>((props, ref) => {
  const { defaultValues, showSubmitBtn = true, organizationId } = props;

  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState<any>();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <h4 className="mb-4">{t('welcomeMessage')}</h4>
        </div>
        <div className="col-12">
          <div className="alert alert-info" role="alert">
            {t('welcomeMessageInfoAlert')}.
          </div>
        </div>
        <div className="col-12">
          <div className="mb-3">
            <div className="form-check">
              <input
                id="welcome-message-vcard"
                className="form-check-input input-primary"
                type="checkbox"
                name="welcome_message.vcard"
                defaultChecked={defaultValues?.welcomeMessage?.vcard}
              />
              <label className="form-check-label" htmlFor="welcome-message-vcard">
                vCard
              </label>
              <small className="form-text text-muted">{t('welcomeMsgVCardCheckboxHint')}</small>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('subject')}:</label>
                <input
                  type="text"
                  className={'form-control' + (formErrors?.['welcome_message.subject']?.length ? ' is-invalid' : '')}
                  name="welcome_message.subject"
                  placeholder={t('subject')}
                  defaultValue={defaultValues?.welcomeMessage?.subject}
                />
                <small className="form-text text-muted">{t('welcomeMsgSubjectHint')}</small>
                {formErrors?.['welcome_message.subject']?.length ? (
                  <FormElementError text={formErrors['welcome_message.subject'][0] || ''} />
                ) : null}
              </div>
              <WelcomeUploader
                organizationId={organizationId}
                formErrors={formErrors}
                initialValue={defaultValues?.welcomeMessage}
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('text')}:</label>
                <textarea
                  className={'form-control' + (formErrors?.['welcome_message.text']?.length ? ' is-invalid' : '')}
                  name="welcome_message.text"
                  placeholder={t('text')}
                  defaultValue={defaultValues?.welcomeMessage?.text}
                  rows={7}
                />
                <small className="form-text text-muted">{t('welcomeMsgTextHint')}</small>
                {formErrors?.['welcome_message.text']?.length ? (
                  <FormElementError text={formErrors['welcome_message.text'][0] || ''} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="my-4" />

      <div className="row">
        <div className="col-12">
          <h4 className="mb-4">{t('postWelcomeMessage')}</h4>
        </div>
        <div className="col-12">
          <div className="alert alert-info" role="alert">
            {t('postWelcomeMessageInfoAlert')}.
          </div>
        </div>
        <div className="col-12">
          <div className="mb-3">
            <div className="form-check">
              <input
                id="post-welcome-message-vcard"
                className="form-check-input input-primary"
                type="checkbox"
                name="post_welcome_message.vcard"
                defaultChecked={defaultValues?.postWelcomeMessage?.vcard}
              />
              <label className="form-check-label" htmlFor="post-welcome-message-vcard">
                vCard
              </label>
              <small className="form-text text-muted">{t('welcomeMsgVCardCheckboxHint')}</small>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('subject')}:</label>
                <input
                  type="text"
                  className={
                    'form-control' + (formErrors?.['post_welcome_message.subject']?.length ? ' is-invalid' : '')
                  }
                  name="post_welcome_message.subject"
                  placeholder={t('subject')}
                  defaultValue={defaultValues?.postWelcomeMessage?.subject}
                />
                <small className="form-text text-muted">{t('welcomeMsgSubjectHint')}</small>
                {formErrors?.['post_welcome_message.subject']?.length ? (
                  <FormElementError text={formErrors['post_welcome_message.subject'][0] || ''} />
                ) : null}
              </div>
              <PostWelcomeUploader
                organizationId={organizationId}
                formErrors={formErrors}
                initialValue={defaultValues?.postWelcomeMessage}
              />
            </div>
            <div className="col-12 col-lg-6">
              <div className="mb-3">
                <label className="form-label">{t('text')}:</label>
                <textarea
                  className={'form-control' + (formErrors?.['post_welcome_message.text']?.length ? ' is-invalid' : '')}
                  name="post_welcome_message.text"
                  placeholder={t('text')}
                  defaultValue={defaultValues?.postWelcomeMessage?.text}
                  rows={7}
                />
                <small className="form-text text-muted">{t('welcomeMsgTextHint')}</small>
                {formErrors?.['post_welcome_message.text']?.length ? (
                  <FormElementError text={formErrors['post_welcome_message.text'][0] || ''} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showSubmitBtn ? (
        <button className="btn btn-primary mt-2" type="submit" disabled={isFormSubmitting}>
          {isFormSubmitting ? t('sending') : t('save')}
        </button>
      ) : null}
    </div>
  );
});
