import { FC, useMemo } from 'react';
import FeatherIcon from 'feather-icons-react';

import { IAdvertisingModalContentSettings, IAdvertisingModalStyleSettings } from '../../../../../types';

import './PreviewAdvModal.css';

interface IProps {
  styles: IAdvertisingModalStyleSettings | null;
  content: IAdvertisingModalContentSettings | null;
}
export const PreviewAdvModal: FC<IProps> = (props) => {
  const { styles, content } = props;

  const _styles = useMemo(
    () => ({
      containerStyle: {
        borderRadius: `${styles?.common.bdRadius}px`,
        backgroundColor: styles?.common.bgColor,
      },
      titleStyle: {
        fontSize: `${styles?.title.fontSize}px`,
        fontFamily: styles?.title.fontFamily,
        color: styles?.title.textColor,
      },
      subtitleStyle: {
        fontSize: `${styles?.subtitle.fontSize}px`,
        fontFamily: styles?.subtitle.fontFamily,
        color: styles?.subtitle.textColor,
      },
      descriptionStyle: {
        fontSize: `${styles?.description.fontSize}px`,
        fontFamily: styles?.description.fontFamily,
        color: styles?.description.textColor,
      },
      ctaButtonStyle: {
        fontSize: `${styles?.ctaButton.fontSize}px`,
        fontFamily: styles?.ctaButton.fontFamily,
        color: styles?.ctaButton.textColor,
        backgroundColor: styles?.ctaButton.bgColor,
      },
      dismissButtonStyle: {
        fontSize: `${styles?.dismissButton.fontSize}px`,
        fontFamily: styles?.dismissButton.fontFamily,
        color: styles?.dismissButton.textColor,
        backgroundColor: styles?.dismissButton.bgColor,
      },
    }),
    [styles]
  );

  return (
    <div
      className="d-flex align-items-center justify-content-center bg-gray-700 position-relative"
      style={{ height: 400 }}
    >
      <div className="preview-modal-container" style={_styles.containerStyle}>
        <div className="preview-modal-title" style={_styles.titleStyle}>
          {content?.title}
        </div>
        <div className="preview-modal-subtitle" style={_styles.subtitleStyle}>
          {content?.subtitle}
        </div>
        <div className="preview-modal-description" style={_styles.descriptionStyle}>
          {content?.description}
        </div>
        <div className="preview-modal-buttons">
          <button type="button" className="btn preview-modal-cta-button" style={_styles.ctaButtonStyle}>
            {content?.cta_text}
          </button>
          <button type="button" className="btn preview-modal-dismiss-button" style={_styles.dismissButtonStyle}>
            {content?.dismiss_text}
          </button>
        </div>
        <button type="button" className="preview-modal-close-button" aria-label="Close">
          <FeatherIcon icon="x" size={20} />
        </button>
      </div>
    </div>
  );
};
