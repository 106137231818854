import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AudienceContent from './common/AudienceContent';

const Audience: FC = () => {
  const { t } = useTranslation();

  return (
    <div id="audience">
      <div className="main-body">
        <div className="page-wrapper">
          <div className="page-header-title">
            <h3 className="m-b-10">{t('audience')}</h3>
          </div>
          <div className="card">
            <AudienceContent />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Audience;
