import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthContext } from './AuthContext';

const ONE_DAY_IN_MILISECONDS = 24 * 60 * 60 * 1000; // 86400000
const EXPIRATION = 30 * ONE_DAY_IN_MILISECONDS; // 30 days in seconds

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem('authToken'));
  // const [authTokenExpiration, setAuthTOkenExpiration] = useState<string | null>(
  //   localStorage.getItem('authTokenExpiration')
  // );
  const [signUpIdExpiration, setSignUpIdExpiration] = useState<string | null>(
    localStorage.getItem('signUpIdExpiration')
  );
  const [signUpId, setSignUpId] = useState<string | null>(localStorage.getItem('signUpId'));

  const onLogIn = useCallback((t: string) => {
    setToken(t);
    localStorage.setItem('authToken', t);

    // const aidExpiration = Date.now() + EXPIRATION;
    // localStorage.setItem('authTokenExpiration', `${aidExpiration}`);
    // setAuthTOkenExpiration(`${aidExpiration}`);
  }, []);

  const onSignUpIdUpdated = useCallback((id: string) => {
    setSignUpId(id);
    localStorage.setItem('signUpId', id);

    const sidExpiration = Date.now() + EXPIRATION;
    localStorage.setItem('signUpIdExpiration', `${sidExpiration}`);
    setSignUpIdExpiration(`${sidExpiration}`);
  }, []);

  const onLogOut = useCallback(() => {
    setToken(null);
    setSignUpId(null);
    localStorage.removeItem('authToken');
    localStorage.removeItem('authTokenExpiration');
    localStorage.removeItem('user');
  }, []);

  useEffect(() => {
    const onInvalidToken = () => {
      onLogOut();
      window.location.href = '/';
    };
    window.addEventListener('invalidToken', onInvalidToken);

    return () => {
      window.removeEventListener('invalidToken', onInvalidToken);
    };
  }, [onLogOut]);

  const defaultValue = useMemo(() => {
    const now = Date.now();
    return {
      isAuthorized: Boolean(token),
      token,
      onLogIn,
      onLogOut,
      signUpId: signUpId && signUpIdExpiration && now < parseInt(signUpIdExpiration) ? signUpId : null,
      onSignUpIdUpdated,
    };
  }, [token, onLogIn, onLogOut, signUpId, signUpIdExpiration, onSignUpIdUpdated]);

  return <AuthContext.Provider value={defaultValue}>{children}</AuthContext.Provider>;
};
