import { ComponentPropsWithoutRef, FC, ReactElement, ReactNode, useMemo } from 'react';
import * as RadixToast from '@radix-ui/react-toast';
import FeatherIcon from 'feather-icons-react';
import { type ToasterToast } from './useToast';

import './Toast.css';

export type ToastActionElement = ReactElement<typeof RadixToast.Action>;
export type ToastProps = ComponentPropsWithoutRef<typeof RadixToast.Root>;

type IProps = Omit<ToasterToast, 'id'>;

export const Toast: FC<IProps> = (props) => {
  const { title, description, action, variant = 'default', ...otherProps } = props;

  const classes = useMemo(() => {
    let classes = 'toast-root';

    if (variant === 'success') {
      classes += ' toast-root--success';
    }
    if (variant === 'error') {
      classes += ' toast-root--error';
    }
    if (variant === 'warning') {
      classes += ' toast-root--warning';
    }
    if (variant === 'info') {
      classes += ' toast-root--info';
    }
    return classes;
  }, [variant]);

  return (
    // 'ToastRoot bg-secondary-subtle d-flex flex-column pointer-events-auto position-relative w-100 align-items-center gap-2 overflow-hidden p-2'
    <RadixToast.Root className={classes} {...otherProps}>
      <div>
        {title ? <RadixToast.Title className="toast-title">{title}</RadixToast.Title> : null}
        {description ? <RadixToast.Description>{description}</RadixToast.Description> : null}
      </div>
      {action}
      <RadixToast.Close className="toast-close-btn">
        <FeatherIcon icon="x" size={16} />
      </RadixToast.Close>
    </RadixToast.Root>
  );
};
