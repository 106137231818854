import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import FeatherIcon from 'feather-icons-react';

import { IPaymentCard } from '../../../types';
import { getPaymentMethods, deletePaymentMethod, updatePaymentMethod } from '../../../api';
import { mapPaymentCards } from '../../../lib/mappers';
import { firebaseAddPaymentInfoEvent } from '../../../lib/analytics/firebaseEvents';
import { BankCard, ConfirmModal, Select } from '../../../components/common';
import { PaymentMethodForm } from './PaymentMethodForm';

interface IProps {
  stripePromise: Promise<any> | null;
}

const PaymentMethodPage: FC<IProps> = (props) => {
  const { stripePromise } = props;
  const { t } = useTranslation();

  const [stripeFormKey, setStripeFormKey] = useState<number>(1);
  const [customerId, setCustomerId] = useState('');
  const [paymentCards, setPaymentCards] = useState<IPaymentCard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCardId, setSelectedCardId] = useState<string>('');
  const [isCardDeleting, setIsCardDeleting] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [cardOwner, setCardOwner] = useState<'organization' | 'person'>('organization');

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    try {
      setIsLoading(true);
      
      const { data: paymentMethods } = await getPaymentMethods();

      if (paymentMethods.customer_id) {
        setCanEdit(paymentMethods.editable);
        setCustomerId(paymentMethods.customer_id);
        setPaymentCards(mapPaymentCards(paymentMethods.items));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmDeleteHandler = (id: string) => {
    setIsOpen(true);
    setSelectedCardId(id);
  };

  const onDeleteCard = async (paymentMethodId: string) => {
    try {
      setIsCardDeleting(true);

      const { data: paymentData } = await deletePaymentMethod(paymentMethodId);

      if ('items' in paymentData) {
        setPaymentCards(mapPaymentCards(paymentData.items));
        setIsOpen(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsCardDeleting(false);
    }
  };

  const onSuccessAddedCard = () => {
    setEditMode(false);
    fetchPaymentMethods();
    firebaseAddPaymentInfoEvent({ payment_type: 'card', currency: 'USD', value: 0 });
  };

  const stripeElementsOptions: StripeElementsOptions = {
    mode: 'setup',
    currency: 'usd',
    // clientSecret,
  };

  const cardOwnerOptions = useMemo(
    () => [
      {
        id: 'organization',
        value: 'organization',
        label: t('organization'),
      },
      {
        id: 'person',
        value: 'person',
        label: t('privatePerson'),
      },
    ],
    [t]
  );

  const cardOwnerChange = (cardOwner: any) => {
    setCardOwner(cardOwner.value);
    setStripeFormKey(stripeFormKey + 1);
  };

  const defaultCardChange = async (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;

    const beforeUpdate = [...paymentCards];

    optimisticUpdate();

    function optimisticUpdate() {
      const newPaymentCards = paymentCards.map((card) => {
        card.default = false;

        if (card.id === target.id) {
          card.default = true;
        }

        return card;
      });
      setPaymentCards(newPaymentCards);
    }

    try {
      const { data } = await updatePaymentMethod(target.id);

      if (data.code) {
        setPaymentCards(beforeUpdate);
        return;
      }

      if ('items' in data) {
        setPaymentCards(mapPaymentCards(data.items));
      }
    } catch (error) {
      console.log(error);
      setPaymentCards(beforeUpdate);
    }
  };

  return (
    <div>
      <div className="page-header-title">
        <h3 className="m-b-10">{t('paymentMethodTitle')}</h3>
      </div>
      <div className="task-detail-right">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h5>{t('paymentCards')}</h5>
            {canEdit ? (
              <button
                className="btn btn-light-secondary d-inline-flex align-items-center"
                onClick={() => setEditMode(!editMode)}
              >
                <FeatherIcon className="mr-2" icon="credit-card" />
                {editMode ? t('cancel') : t('paymentMethodTab.addNewCard')}
              </button>
            ) : null}
          </div>
          <div className="card-block">
            <div className="alert alert-info mb-5">{t('warnings.no-store-card')}</div>
            {editMode ? (
              <>
                {canEdit && !isLoading && stripePromise ? (
                  <>
                    <div className="mb-3">
                      <Select
                        id="payer-type"
                        label={t('Add card as:')}
                        options={cardOwnerOptions}
                        initialValue={cardOwnerOptions[0]}
                        onChange={cardOwnerChange}
                      />
                    </div>
                    <Elements key={stripeFormKey} stripe={stripePromise} options={stripeElementsOptions}>
                      <PaymentMethodForm customerId={customerId} onSuccess={onSuccessAddedCard} cardOwner={cardOwner} />
                    </Elements>
                  </>
                ) : (
                  <div>Loading...</div>
                )}
              </>
            ) : (
              <>
                {paymentCards.length > 0 ? (
                  <div className="row g-4">
                    {paymentCards.map((card, index) => (
                      <div key={card.id} className="col-12 col-md-6">
                        <input type="radio" id={card.id} name="paymentCard" onChange={defaultCardChange} hidden />
                        <label htmlFor={card.id} className="w-100" style={{ cursor: 'pointer' }}>
                          <BankCard
                            name={card.cardholderName}
                            type={card.type}
                            last4Digits={card.last4Digits}
                            expireDate={card.expiryDate}
                            cardType={card.cardType}
                            onDelete={() => onConfirmDeleteHandler(card.id)}
                            canDelete={canEdit}
                            defaultCard={card.default}
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-muted m-t-0">{t('noCard')}!</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {isOpen ? (
        <ConfirmModal
          isOpen={isOpen}
          title={t('confirmAction')}
          text={t('confirmDeletion')}
          onCancel={() => setIsOpen(false)}
          onConfirm={() => onDeleteCard(selectedCardId)}
          confirmText={isCardDeleting ? t('deleting') + '...' : t('confirmDeletionBtn')}
          dangerConfirmButton
        />
      ) : null}
    </div>
  );
};
export default PaymentMethodPage;
