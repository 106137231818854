import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentElement, LinkAuthenticationElement, AddressElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeAddressElementOptions, StripeError } from '@stripe/stripe-js';

import { createPaymentIntent } from '../../../api';

interface IProps {
  customerId: string;
  onSuccess?: () => void;
  cardOwner?: 'organization' | 'person';
}

export const PaymentMethodForm: FC<IProps> = (props) => {
  const { customerId, onSuccess, cardOwner = 'organization' } = props;
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const email = localStorage.getItem('userEmail') || '';

  const handleError = (error: StripeError) => {
    setIsLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    const { data: intent } = await createPaymentIntent(customerId);
    if (intent.client_secret) {
      setClientSecret(intent.client_secret);
    }

    // Confirm the SetupIntent using the details collected by the Payment Element
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      clientSecret: intent.client_secret,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/billing/payment-method`,
      },
      redirect: 'if_required',
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      if (setupIntent && setupIntent.status === 'succeeded') {
        // Your customer will be redirected to your `return_url` automatically
        // successNotification()
        onSuccess?.();
      } else if (setupIntent && setupIntent.status === 'requires_action') {
        // Let users know that they need to authenticate with their bank
        // setErrorMessage('Please authenticate with your bank to complete the setup.');
      } else {
        // Handle other statuses or error cases
        setErrorMessage('Failed to set up the payment method.');
      }
    }

    setIsLoading(false);
  };

  const addressElOptions: StripeAddressElementOptions = useMemo(
    () => ({
      mode: 'billing',
      // Passing an empty object as the `defaultValues` to short-circuit
      // the default value setting in the AddressElement.
      defaultValues: {},
      display: { name: cardOwner === 'organization' ? 'organization' : 'split' },
    }),
    [cardOwner]
  );

  return (
    <>
      {/* Show any error or success messages */}
      {errorMessage && (
        <div id="payment-message" className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <form id="payment-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement
          id="link-authentication-element"
          // Access the email value like so:
          // onChange={(event) => {
          //  setEmail(event.value.email);
          // }}
          //
          // Prefill the email field like so:
          options={{ defaultValues: { email } }}
        />
        <div className="mt-2">
          <PaymentElement id="payment-element" />
        </div>
        <div className="mt-2">
          <AddressElement id="address-element" options={addressElOptions} />
        </div>
        <button id="submit" className="btn btn-primary mt-4" disabled={isLoading || !stripe || !elements}>
          {t('paymentMethodTab.addButton')}
        </button>
      </form>
    </>
  );
};
