import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import { ISegment } from '../../../types';
import { deleteSegmentAPI, getAllSegmentsByOrganizationAPI } from '../../../api';
import { ConfirmModal } from '../../../components/common';

const OrganizationSegments: FC = () => {
  const { t } = useTranslation();
  const { organizationId = '' } = useParams();

  const [organizationName, setOrganizationName] = useState<string>('');
  const [segments, setSegments] = useState<ISegment[]>([]);
  const [isConfirmDeletionModalOpen, setIsConfirmDeletionModalOpen] = useState<boolean>(false);
  const [selectedSegmentId, setSelectedSegmentId] = useState<string>('');
  const [isSegmentDeleting, setIsSegmentDeleting] = useState<boolean>(false);

  useEffect(() => {
    fetchAllSegments();
  }, []);

  const fetchAllSegments = async () => {
    try {
      const { data: segmentsData } = await getAllSegmentsByOrganizationAPI(organizationId);

      setOrganizationName(segmentsData.organization_name);
      setSegments(segmentsData.items);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickDeleteSegment = async (segmentId: string) => {
    setSelectedSegmentId(segmentId);
    setIsConfirmDeletionModalOpen(true);
  };

  const deleteSegmentHandler = async (segmentId: string) => {
    try {
      if (!organizationId) {
        console.log('Organization ID is missing');
        return;
      }

      setIsSegmentDeleting(true);

      const { data: segmentData } = await deleteSegmentAPI(organizationId, segmentId);

      fetchAllSegments();
    } catch (error) {
      console.log(error);
    } finally {
      setIsConfirmDeletionModalOpen(false);
      setIsSegmentDeleting(false);
    }
  };

  return (
    <>
      <div className="page-header-title">
        <h3 className="m-b-10">{organizationName}</h3>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('segments')}</h5>
            <Link className="btn btn-primary" to={`/organization/${organizationId}/segment/create`}>
              {t('create')}
            </Link>
          </div>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th className="bg-gray-300 text-uppercase">ID</th>
                <th className="bg-gray-300 text-uppercase">{t('name')}</th>
                <th className="bg-gray-300 text-uppercase">{t('createdDate')}</th>
                <th className="text-end bg-gray-300 text-uppercase">{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {segments.map((segment, index) => (
                <tr key={segment.id}>
                  <td className="align-middle">{index + 1}</td>
                  <td className="align-middle">{segment.name}</td>
                  <td className="align-middle">{new Intl.DateTimeFormat().format(new Date(segment.create_date))}</td>
                  <td className="d-flex column-gap-2 align-items-center justify-content-end">
                    <Link
                      className="avtar avtar-xs btn-link-secondary"
                      to={`/organization/${organizationId}/segment/${segment.id}/edit`}
                    >
                      <FeatherIcon icon="edit" size={20} />
                    </Link>
                    <button
                      className="avtar avtar-xs btn-link-danger border-0"
                      onClick={() => onClickDeleteSegment(segment.id)}
                    >
                      <FeatherIcon icon="trash-2" size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isConfirmDeletionModalOpen ? (
        <ConfirmModal
          isOpen={isConfirmDeletionModalOpen}
          title={t('confirmAction')}
          text={t('confirmDeletion')}
          onCancel={() => setIsConfirmDeletionModalOpen(false)}
          onConfirm={() => deleteSegmentHandler(selectedSegmentId)}
          confirmText={isSegmentDeleting ? 'Deleting...' : 'Yes, delete it'}
          dangerConfirmButton
        />
      ) : null}
    </>
  );
};

export default OrganizationSegments;
