import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  IAdvertisingModalContentSettings,
  IAdvertisingModalSettings,
  IAdvertisingModalStyleSettings,
} from '../../../types';
import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';
import { getAdvertisingModalAPI, updateAdvertisingModalAPI } from '../../../api';
import { PreviewAdvModal } from './components';
import { AdvertisingBuilderForm } from '../../../components/forms';

const OrganizationAdvertisingModalEditPage: FC = () => {
  const { t } = useTranslation();
  const { organizationId = '', modalId = '' } = useParams();
  const navigate = useNavigate();

  const [modalSettings, setModalSettings] = useState<IAdvertisingModalSettings | null>(null);
  const [modalStyleSettings, setModalStyleSettings] = useState<IAdvertisingModalStyleSettings | null>(null);
  const [modalContentSettings, setModalContentSettings] = useState<IAdvertisingModalContentSettings | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const { data: modalData } = await getAdvertisingModalAPI(organizationId, modalId);

        setModalSettings(modalData);
        setModalStyleSettings(modalData.configuration);
        setModalContentSettings(modalData.content);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const changeStyleSettingsHandler = (newSettings: IAdvertisingModalStyleSettings) => {
    setModalStyleSettings(newSettings);
  };
  const changeContentSettingsHandler = (newSettings: IAdvertisingModalContentSettings) => {
    setModalContentSettings(newSettings);
  };

  const submitHandler = async (data: Partial<IAdvertisingModalSettings>) => {
    try {
      await updateAdvertisingModalAPI(organizationId, modalId, data);

      navigate(`/organization/${organizationId}/advertising-modals`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={`/organization/${organizationId}/advertising-modals`}>{t('organizationModals')}</Link>
        </li>
        <li className="breadcrumb-item" aria-current="page">
          {t('modalBuilder')}
        </li>
      </ul>

      <div className="card mb-0">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Edit Advertising Modal</h5>
            {/* <Link className="btn btn-primary" to={`/organization/${organizationId}/advertising-modal/builder`}>
            {t('create')}
          </Link> */}
          </div>
        </div>
        <div className="card-body">
          {modalSettings && (
            <AdvertisingBuilderForm
              onStyleSettingsChange={changeStyleSettingsHandler}
              onContentSettingsChange={changeContentSettingsHandler}
              onSubmit={submitHandler}
              initialValues={modalSettings}
            />
          )}
        </div>
      </div>
      <div className="card shadow-none mb-0">
        <div className="card-header">
          <h5>{t('preview')}</h5>
        </div>
        <div className="card-body">
          {modalSettings && <PreviewAdvModal styles={modalStyleSettings} content={modalContentSettings} />}
        </div>
      </div>
    </>
  );
};

export default OrganizationAdvertisingModalEditPage;
