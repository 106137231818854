/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { campaignsAPIDeleteCampaignData, campaignsAPIPatchCampaignDetailsData } from '../../../api/campaigns';

interface ModalProps {
  isOpen: boolean;
  onOpen: (open: boolean) => void;
  status: string;
  action?: string;
  campaignId: string;
  data: any;
}

const ConfirmActionModal: React.FC<ModalProps> = ({ isOpen, onOpen, status, data, campaignId, action }) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(isOpen);
  const [buttonText, setButtonText] = useState('');

  const onClose = useCallback(() => {
    setShow(!show);
    onOpen(false);
  }, [onOpen, show]);

  const { t } = useTranslation();

  useEffect(() => {
    if (action) {
      if (action === 'cancel') setButtonText(t('cancelCompaign'));
      if (action === 'start') setButtonText(t('startCampaign'));
      if (action === 'delete') setButtonText(t('deleteCampaign'));
      if (action === 'pause') setButtonText(t('pauseCampaign'));
    }
  }, [action, t]);

  const onStartCampaign = useCallback(
    (status: string) => {
      (async () => {
        try {
          const { data: campaignsData } = await campaignsAPIPatchCampaignDetailsData(data.id, { status });

          navigate('/campaigns');
        } catch (e) {
          console.log(e);
        }
      })();
    },
    [navigate, data]
  );

  const onDeleteCampaign = useCallback(
    (status: string) => {
      (async () => {
        try {
          const { data: campaignsData } = await campaignsAPIDeleteCampaignData({ ids: [`${campaignId}`], status });

          navigate('/campaigns');
        } catch (e) {
          console.log(e);
        }
      })();
    },
    [navigate, campaignId]
  );

  const onCampaign = useCallback(() => {
    if (status === 'canceled' && action === 'cancel') {
      onDeleteCampaign('canceled');
    }
    if (status === 'paused' && action === 'pause') {
      onStartCampaign('paused');
    }
    if (status === 'pending' && action === 'start') {
      onStartCampaign('pending');
    }
    if (status === 'deleted' && action === 'delete') {
      onDeleteCampaign('deleted'); //
    }
    if (status === 'processing') {
      //onStartCampaign('paused');
      //onDeleteCampaign('deleted'); //
    }
  }, [status, onDeleteCampaign, onStartCampaign, action]);

  return (
    <>
      {show ? (
        <>
          <div className="modal-container modal-container_top"></div>
          <div
            className="modal modal_top fade show"
            role="dialog"
            aria-hidden="true"
            style={{ display: 'block', zIndex: 2000 }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLiveLabel">
                    {t('confirmAction')}
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{t('confirmDeletion')}</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onClose}>
                    {t('Close')}
                  </button>
                  <button type="button" className="btn btn-primary" onClick={onCampaign}>
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default ConfirmActionModal;
