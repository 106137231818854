import { AxiosResponse } from 'axios';
import { baseApi } from './baseApi';

export const campaignsAPIGetCampaignsData = async (): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/audience-list/');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPIGetCampaignsFilterData = async (filterQuery: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/campaigns/?` + new URLSearchParams(filterQuery));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPIDeleteCampaignData = async (data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.delete('/dashboard/campaigns/', { data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPIGetCreateCampaign = async (id: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/campaign/new/${id}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPIPostCreateCampaign = async (data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/campaign/create/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPIPutCampaignDetailsData = async (id: string, data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.put(`/dashboard/campaign/${id}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPIPatchCampaignDetailsData = async (id: string, data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.patch(`/dashboard/campaign/${id}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPIGetCampaignDetailsData = async (id: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/campaign/${id}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPICreateCommand = async (orgId: string, data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.post(`/dashboard/command/${orgId}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPIUpdateCommand = async (
  { orgId, commandId }: { orgId: string; commandId: string },
  data: any
): Promise<AxiosResponse> => {
  try {
    return await baseApi.patch(`/dashboard/command/${orgId}/${commandId}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPIDeleteCommand = async ({
  orgId,
  commandId,
}: {
  orgId: string;
  commandId: string;
}): Promise<AxiosResponse> => {
  try {
    return await baseApi.delete(`/dashboard/command/${orgId}/${commandId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const campaignsAPIPostTestMessage = async (data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/campaign/test-message/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};
