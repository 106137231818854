import { AxiosResponse } from 'axios';
import { baseApi } from './baseApi';

export const dashboardAPIGetData = async (): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/home/');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const dashboardAPIPostEmailData = async (data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.post('/dashboard/home/', data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const dashboardAPIGetCompaniesData = async (): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/organizations/');
  } catch (error) {
    return Promise.reject(error);
  }
};
