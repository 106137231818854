import type { InferOutput } from 'valibot';

import { pipe, object, string, nonEmpty, url, optional, union, literal, transform, rawTransform } from 'valibot';

export const SegmentsFormSchema = pipe(
  object({
    name: pipe(string(), nonEmpty('Please enter the name.')),
    description: pipe(string(), nonEmpty('Please enter the description.')),
    opt_in_days_ago_is_checked: optional(
      pipe(
        string(),
        transform((input) => input === 'on')
      )
    ),
    opt_in_days_ago: optional(string()),
    last_delivered_msg_ago_is_checked: optional(
      pipe(
        string(),
        transform((input) => input === 'on')
      )
    ),
    last_delivered_msg_ago: optional(string()),
    visited_page_is_checked: optional(
      pipe(
        string(),
        transform((input) => input === 'on')
      )
    ),
    visited_page: optional(union([literal(''), pipe(string(), url())])),
  }),
  rawTransform(({ dataset, addIssue, NEVER }) => {
    console.log('dataset.value', dataset.value);
    const values = dataset.value;

    if (values.opt_in_days_ago_is_checked) {
      delete values.opt_in_days_ago_is_checked;
    } else {
      delete values.opt_in_days_ago;
    }

    if (values.last_delivered_msg_ago_is_checked) {
      delete values.last_delivered_msg_ago_is_checked;
    } else {
      delete values.last_delivered_msg_ago;
    }

    if (values.visited_page_is_checked) {
      delete values.visited_page_is_checked;
    } else {
      delete values.visited_page;
    }

    return dataset.value;
  })
);

export type ISegmentsForm = InferOutput<typeof SegmentsFormSchema>;
