import { AxiosResponse } from 'axios';
import { baseApi } from './baseApi';

interface IBuyPackageParams {
  packageId: string;
  organizationId: string;
  paymentMethodId: string;
}

/* PADDLE start */
export const billingAPIGetPaymentData = async (): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/billing/payment-method/');
  } catch (error) {
    return Promise.reject(error);
  }
};

export const billingAPIDeletePaymentData = async (id: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.delete(`/dashboard/billing/payment-method/${id}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

/* PADDLE end */

/* STRIPE start */
export const getPaymentMethods = async (): Promise<AxiosResponse> => {
  try {
    return await baseApi.get('/dashboard/billing/payment-method-stripe/', {
      headers: { 'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePaymentMethod = async (paymentMethodId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.put(`/dashboard/billing/payment-method-stripe/${paymentMethodId}/`, {
      headers: { 'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deletePaymentMethod = async (paymentMethodId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.delete(`/dashboard/billing/payment-method-stripe/${paymentMethodId}/`, {
      headers: { 'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createPaymentIntent = async (customerId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/billing/payment-method-stripe-intent/${customerId}/`, {
      headers: { 'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

/* STRIPE end */

export const getOrganizationPackages = async (organizationId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/packages/${organizationId}/`, {
      headers: { 'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const buyPackage = async ({
  organizationId,
  packageId,
  paymentMethodId,
}: IBuyPackageParams): Promise<AxiosResponse> => {
  try {
    return await baseApi.post(`/dashboard/buy-package/${organizationId}/${packageId}/${paymentMethodId}/`, {
      headers: { 'Loop-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
