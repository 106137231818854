import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import { IAdvertisingModal, TAdvertisingModalStatuses } from '../../../types';
import { deleteAdvertisingModalAPI, getAllAdvertisingModalsByOrganizationAPI } from '../../../api';
import { ConfirmModal } from '../../../components/common';

const OrganizationAdvertisingModalsPage: FC = () => {
  const { t } = useTranslation();
  const { organizationId = '' } = useParams();

  const [organizationName, setOrganizationName] = useState<string>('');
  const [advertisingModals, setAdvertisingModals] = useState<IAdvertisingModal[]>([]);
  const [isConfirmDeletionModalOpen, setIsConfirmDeletionModalOpen] = useState<boolean>(false);
  const [selectedAdvertisingModalId, setSelectedAdvertisingModalId] = useState<string>('');
  const [isAdvertisingModalDeleting, setIsAdvertisingModalDeleting] = useState<boolean>(false);

  useEffect(() => {
    fetchAdvertisingModals();
  }, []);

  const selectStatusBadge = useMemo(
    () => (status: TAdvertisingModalStatuses) => {
      switch (status) {
        case 'draft':
          return <span className="badge rounded-pill text-bg-secondary">{t('draft')}</span>;
        case 'active':
          return <span className="badge rounded-pill text-bg-success">{t('active')}</span>;
        default:
          return null;
      }
    },
    [t]
  );

  const fetchAdvertisingModals = async () => {
    try {
      if (!organizationId) {
        console.log('Organization ID is missing');
        return;
      }

      const { data: modalsData } = await getAllAdvertisingModalsByOrganizationAPI(organizationId);

      setOrganizationName(modalsData.organization.name);
      setAdvertisingModals(modalsData.items);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickDeleteSegment = async (modalId: string) => {
    setSelectedAdvertisingModalId(modalId);
    setIsConfirmDeletionModalOpen(true);
  };

  const deleteAdvertisingModalHandler = async (modalId: string) => {
    try {
      if (!organizationId || !modalId) {
        console.log('Organization ID or Modal ID is missing');
        return;
      }

      setIsAdvertisingModalDeleting(true);

      await deleteAdvertisingModalAPI(organizationId, modalId);

      fetchAdvertisingModals();
    } catch (error) {
      console.log(error);
    } finally {
      setIsConfirmDeletionModalOpen(false);
      setIsAdvertisingModalDeleting(false);
    }
  };

  return (
    <>
      <div className="page-header-title">
        <h3 className="m-b-10">{organizationName}</h3>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{t('modals')}</h5>
            <Link className="btn btn-primary" to={`/organization/${organizationId}/advertising-modal/builder`}>
              {t('create')}
            </Link>
          </div>
        </div>
        <div className="card-body">
          {advertisingModals.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th className="bg-gray-300 text-uppercase">ID</th>
                  <th className="bg-gray-300 text-uppercase">{t('name')}</th>
                  <th className="bg-gray-300 text-uppercase">{t('createdDate')}</th>
                  <th className="bg-gray-300 text-uppercase">{t('status')}</th>
                  <th className="text-end bg-gray-300 text-uppercase">{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {advertisingModals.map((modal, index) => (
                  <tr key={modal.id}>
                    <td className="align-middle">{index + 1}</td>
                    <td className="align-middle">{modal.name}</td>
                    <td className="align-middle">{new Intl.DateTimeFormat().format(new Date(modal.create_date))}</td>
                    <td className="align-middle">{selectStatusBadge(modal.status)}</td>
                    <td className="d-flex column-gap-2 align-items-center justify-content-end">
                      <Link
                        className="avtar avtar-xs btn-link-secondary"
                        to={`/organization/${organizationId}/advertising-modal/${modal.id}/edit`}
                      >
                        <FeatherIcon icon="edit" size={20} />
                      </Link>
                      <button
                        className="avtar avtar-xs btn-link-danger border-0"
                        onClick={() => onClickDeleteSegment(modal.id)}
                      >
                        <FeatherIcon icon="trash-2" size={20} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center mb-0">{t('noResults')}</p>
          )}
        </div>
      </div>

      {isConfirmDeletionModalOpen ? (
        <ConfirmModal
          isOpen={isConfirmDeletionModalOpen}
          title={t('confirmAction')}
          text={t('confirmDeletion')}
          onCancel={() => setIsConfirmDeletionModalOpen(false)}
          onConfirm={() => deleteAdvertisingModalHandler(selectedAdvertisingModalId)}
          confirmText={isAdvertisingModalDeleting ? 'Deleting...' : 'Yes, delete it'}
          dangerConfirmButton
        />
      ) : null}
    </>
  );
};

export default OrganizationAdvertisingModalsPage;
