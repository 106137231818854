/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { audienceAPIDeleteContact, audienceAPIGetAudienceDetailsData } from '../../../api/audience';
import AudienceDetailsModal from '../modal/AudienceDetailsModal';

interface MessageRowProps {
  id: string;
  value: string;
  valueType: string;
  onDetails: (id: string) => void;
  onDeleteItems: (id: string, check: boolean) => void;
  createDate: Date;
  check?: any;
  editable?: boolean;
}
const MessageRow: React.FC<MessageRowProps> = ({
  id,
  onDetails,
  value,
  valueType,
  createDate,
  onDeleteItems,
  check,
  editable,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const checkBoxOnChange = useCallback(
    (id: string) => {
      if (id && !checked) {
        onDeleteItems(id, true);
        setChecked(true);
      } else {
        setChecked(false);
        onDeleteItems(id, false);
      }
    },
    [checked, onDeleteItems, setChecked]
  );

  return (
    <div className="table-row d-flex align-items-center cell" style={{ padding: 10, cursor: 'pointer' }}>
      {editable ? (
        <input
          onChange={() => checkBoxOnChange(id)}
          className="form-check-input input-primary m-t-2m m-r-10"
          type="checkbox"
          id="customCheckc1"
          checked={checked}
        />
      ) : null}
      <div className="d-flex align-items-center w-100" onClick={() => onDetails(id)}>
        <div className="cell">{value}</div>
        <div className="cell">{t(valueType)}</div>
        <div className="cell">
          {DateTime.fromISO(`${createDate}`).toLocaleString({
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </div>
      </div>
    </div>
  );
};

interface MessagesTableProps {
  data: any;
  onChange?: (c: boolean) => void;
  sendType?: boolean;
  onSendMessage?: (sendItems: any) => void;
}

const MessagesTable: React.FC<MessagesTableProps> = ({ data, onChange, sendType, onSendMessage }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [detailsData, setDetailsData] = useState(false);
  const [deletedItems, setDeletedItems] = useState<any[]>([]);
  const [error, setError] = useState<Error | null>(null);

  const onOpenModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (onSendMessage) {
      onSendMessage(deletedItems);
    }
  }, [onSendMessage, deletedItems]);

  const onDetails = useCallback(
    (id: string) => {
      (async () => {
        try {
          if (id) {
            const { data: audienceData } = await audienceAPIGetAudienceDetailsData(id);

            setDetailsData(audienceData);
            setIsOpen(true);
          }
        } catch (e) {
          setError(e as Error);
          console.log(error);
        }
      })();
    },
    [error]
  );

  const onDeleteItems = useCallback(
    (id: string, check: boolean) => {
      if (id && check) {
        const items = [...deletedItems, id];
        const uniqueNames = new Set(items);
        setDeletedItems(Array.from(uniqueNames));
      } else if (id && !check) {
        const newArray = deletedItems.filter((i) => {
          return i !== id;
        });
        setDeletedItems(newArray);
      }
    },
    [deletedItems]
  );

  const onDeleteItem = useCallback(() => {
    if (deletedItems) {
      (async () => {
        try {
          const { data: audienceData } = await audienceAPIDeleteContact({ ids: [`${deletedItems}`] });

          if (onChange) {
            onChange(true);
            setDeletedItems([]);
          }
        } catch (e) {
          setError(e as Error);
          console.log(error);
        }
      })();
    }
  }, [error, deletedItems, onChange]);

  return (
    <div className="card-block adaptive-table">
      {deletedItems.length !== 0 && !onSendMessage ? (
        <div className="d-flex m-b-10" style={{ position: 'sticky', top: 100 }}>
          <div
            style={{ backgroundColor: '#FFF' }}
            className="btn btn-sm btn-outline-primary d-flex justify-content-center align-items-center"
            onClick={onDeleteItem}
          >
            <div className="d-flex justify-content-center align-items-center">
              {sendType ? 'Send items' : 'Delete items?'}
            </div>
          </div>
        </div>
      ) : null}
      <div className="title-row d-flex align-items-center">
        {onChange !== undefined ? <span className="empty-check m-r-10" /> : null}
        <div>{t('contact')}</div>
        <div>{t('type')}</div>
        <div>{t('optInDate')}</div>
      </div>

      {data.map((item: any, index: number) => {
        return (
          <MessageRow
            key={index}
            onDetails={onDetails}
            id={item.id}
            value={item.value}
            valueType={item.value_type}
            createDate={item.create_date}
            onDeleteItems={onDeleteItems}
            editable={onChange !== undefined}
            check={deletedItems}
          />
        );
      })}
      {isOpen ? <AudienceDetailsModal onOpen={onOpenModal} isOpen={isOpen} data={detailsData} /> : null}
    </div>
  );
};
export default MessagesTable;
