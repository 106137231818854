import { FC, FormEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { flatten, safeParse as valibotSafeParse } from 'valibot';

import { getUnifiedFormData } from '../../lib/getUnifiedFormData';
import { TeamFormSchema } from '../../schemes';
import { inviteParticipantAPI } from '../../api';
import { FormElementError } from '../common';
import Toast from '../toasts/Toast';

interface IProps {
  onInviteSent?: () => void;
}

export const InviteToTeam: FC<IProps> = (props) => {
  const { onInviteSent } = props;
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const [formErrors, setFormErrors] = useState<any>();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isFormSuccess, setIsFormSuccess] = useState<boolean>(false);

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;

    const parseFormData = getUnifiedFormData(target);

    // console.log('parseFormData', parseFormData);

    const validate = valibotSafeParse(TeamFormSchema, parseFormData);

    // console.log('validate', validate);

    if (validate.success) {
      setFormErrors(undefined);

      try {
        setIsFormSubmitting(true);

        const { data: inviteData } = await inviteParticipantAPI(validate.output);

        formRef.current?.reset();

        onInviteSent && onInviteSent();

        setIsFormSuccess(true);
        setTimeout(() => {
          setIsFormSuccess(false);
        }, 3000);
      } catch (error) {
        console.error(error);
      } finally {
        setIsFormSubmitting(false);
      }
    } else {
      const flattenErrors = flatten<typeof TeamFormSchema>(validate.issues);
      setFormErrors(flattenErrors.nested);
      // console.log('formErrors', flattenErrors.nested);
    }
  };

  return (
    <>
      <form ref={formRef} onSubmit={submitHandler} noValidate>
        <div className="input-group">
          <input
            type="email"
            className={'form-control' + (formErrors?.email?.length ? ' is-invalid' : '')}
            name="email"
            placeholder="Enter the user email"
          />
          <button type="submit" className="btn btn-primary" disabled={isFormSubmitting}>
            {t('invite')}
          </button>
          {formErrors?.email?.length ? <FormElementError text={formErrors.email[0] || ''} /> : null}
        </div>
      </form>

      {isFormSuccess ? (
        <div className="mt-4">
          <Toast message={'Invite has been sent!'} />
        </div>
      ) : null}
    </>
  );
};
