import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { mergician } from 'mergician';
import FeatherIcon from 'feather-icons-react';

import { createOrganizationAPI, getDefaultValuesCreateOrganizationAPI } from '../../../api';
import { IOrganizationDefaultValues, IOrganizationDetails, IVCard } from '../../../types';
import { mapOrgDefaultValues } from '../../../lib/mappers';
import { OrganizationDetailsForm, OrgDefaultValuesForm, VCardForm } from '../../../components/forms';

const OrganizationCreatePage: FC = () => {
  const { t } = useTranslation();

  const organizationDetailsFormRef = useRef<HTMLFormElement>(null);
  const orgDefaultValuesFormRef = useRef<HTMLFormElement>(null);
  const orgWelcomeMessageFormRef = useRef<HTMLFormElement>(null);
  const vCardFormRef = useRef<HTMLFormElement>(null);

  const [organizationId, setOrganizationId] = useState<string>('');
  const [preparedData, setPreparedData] = useState<any>(null);
  const [orgDetails, setOrgDetails] = useState<Partial<IOrganizationDetails> | null>(null);
  const [orgDefaultValues, setOrgDefaultValues] = useState<IOrganizationDefaultValues | null>(null);
  const [orgWelcomeMessage, setOrgWelcomeMessage] = useState<Pick<
    IOrganizationDefaultValues,
    'welcomeMessage' | 'postWelcomeMessage'
  > | null>(null);
  const [vCard, setVCard] = useState<Partial<IVCard> | null>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [createdOrganizationId, setCreatedOrganizationId] = useState<number>();
  const tabs = useMemo(
    () => [
      { label: t('organizationDetails'), iconName: 'file-text', formEl: organizationDetailsFormRef },
      { label: t('defaultValues'), iconName: 'file-text', formEl: orgDefaultValuesFormRef },
      { label: t('welcome'), iconName: 'smile', formEl: orgWelcomeMessageFormRef },
      { label: t('contactData'), iconName: 'users', formEl: vCardFormRef },
      { label: t('finish'), iconName: 'thumbs-up' },
    ],
    [t]
  );

  useEffect(() => {
    (async () => {
      try {
        const { data: organizationData } = await getDefaultValuesCreateOrganizationAPI();
        // console.log('response', response);
        if (organizationData?.id) {
          setOrganizationId(organizationData.id);
        }
        
        if (organizationData?.countries) {
          setOrgDetails({ countries: organizationData.countries });
        }
        if (organizationData?.default_values) {
          const mappedDefValues = mapOrgDefaultValues(organizationData.default_values);

          if (mappedDefValues) {
            setOrgDefaultValues(mappedDefValues);
            setOrgWelcomeMessage({
              welcomeMessage: mappedDefValues.welcomeMessage,
              postWelcomeMessage: mappedDefValues.postWelcomeMessage,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const prevTabHandler = () => {
    setSelectedTabIndex((prev) => prev - 1);
  };

  const nextTabHandler = () => {
    const event = new Event('submit', { cancelable: true, bubbles: true });
    tabs[selectedTabIndex].formEl?.current?.dispatchEvent(event);
    // setSelectedTabIndex((prev) => prev + 1);
  };

  const changeTabHandler = (index: number) => {
    setSelectedTabIndex(index);
  };

  const successSubmitHandler = async (index: number, data?: any) => {
    if (data) {
      setPreparedData((prev: any) => mergician({ ...prev }, data));
    }

    if (data.name && data.website && data.support_url) {
      setVCard({ organizationName: data.name, websiteUrl: data.website, supportUrl: data.support_url });
    }

    // when last tab finished
    if (index === 4) {
      try {
        const { data: organization } = await createOrganizationAPI(mergician({ ...preparedData }, data));
        if (organization?.id) {
          setCreatedOrganizationId(organization.id);
        }
      } catch (error) {
        console.error(error);
        return;
      }
    }

    setSelectedTabIndex(index);
  };

  return (
    <div className="page-wrapper">
      <div className="page-header-title">
        <h2 className="h3 mb-4">{t('addNewOrganization')}</h2>
      </div>

      <div id="basicwizard" className="form-wizard row justify-content-center">
        <div className="col-12">
          <TabGroup onChange={changeTabHandler} selectedIndex={selectedTabIndex}>
            <div className="card">
              <div className="card-body p-3">
                <TabList as="ul" className="nav nav-pills nav-justified align-items-center">
                  {tabs.map((tab, idx) => (
                    <li key={idx} className="nav-item">
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          <button
                            className={'nav-link justify-content-center' + (selected ? ' active' : '')}
                            disabled={
                              idx > selectedTabIndex || (selectedTabIndex === tabs.length - 1 && idx < tabs.length - 1)
                            }
                          >
                            <FeatherIcon className="feather mr-2" icon={tab.iconName} size={20} /> {tab.label}
                          </button>
                        )}
                      </Tab>
                    </li>
                  ))}
                </TabList>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div id="bar" className="progress mb-4" style={{ height: '7px' }}>
                  <div
                    className="bar progress-bar progress-bar-striped progress-bar-animated bg-success"
                    style={{ width: `${(selectedTabIndex + 1) * 20}%` }}
                  />
                </div>
                <TabPanels className="tab-content">
                  <TabPanel className={'tab-pane' + (selectedTabIndex === 0 ? ' active' : '')} unmount={false}>
                    <div className="text-center my-5">
                      <h3>{t('organizationDetails')}</h3>
                      {/* <small className="text-muted">
                        Let us know your name and email address. Use an address you don't mind other users contacting
                        you at
                      </small> */}
                    </div>
                    <OrganizationDetailsForm
                      ref={organizationDetailsFormRef}
                      onSuccessSubmit={(data: any) => successSubmitHandler(1, data)}
                      showSubmitBtn={false}
                      defaultValues={orgDetails}
                    />
                  </TabPanel>
                  <TabPanel className={'tab-pane' + (selectedTabIndex === 1 ? ' active' : '')} unmount={false}>
                    <div className="text-center my-5">
                      <h3>{t('defaultValues')}</h3>
                    </div>
                    <OrgDefaultValuesForm
                      ref={orgDefaultValuesFormRef}
                      organizationId={organizationId}
                      onSuccessSubmit={(data: any) => successSubmitHandler(2, data)}
                      showSubmitBtn={false}
                      showWelcomeMessage={false}
                      defaultValues={orgDefaultValues}
                      disableSubmit={true}
                    />
                  </TabPanel>
                  <TabPanel className={'tab-pane' + (selectedTabIndex === 2 ? ' active' : '')} unmount={false}>
                    <div className="text-center my-5">
                      <h3>{t('welcome')}</h3>
                    </div>
                    <OrgDefaultValuesForm
                      ref={orgWelcomeMessageFormRef}
                      id="org-welcome-message-form"
                      organizationId={organizationId}
                      onSuccessSubmit={(data: any) => successSubmitHandler(3, data)}
                      showSubmitBtn={false}
                      onlyWelcomeMessageForm
                      defaultValues={orgDefaultValues}
                      disableSubmit={true}
                    />
                  </TabPanel>
                  <TabPanel className={'tab-pane' + (selectedTabIndex === 3 ? ' active' : '')} unmount={false}>
                    <div className="text-center my-5">
                      <h3>{t('contactData')}</h3>
                    </div>
                    <VCardForm
                      ref={vCardFormRef}
                      organizationId={organizationId}
                      onSuccessSubmit={(data: any) => successSubmitHandler(4, data)}
                      showSubmitBtn={false}
                      defaultValues={vCard}
                      disableSubmit={true}
                    />
                  </TabPanel>
                  <TabPanel className={'tab-pane' + (selectedTabIndex === 4 ? ' active' : '')} unmount={false}>
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-6">
                        <div className="text-center">
                          <span className="fs-1">🎉</span>
                          <h3 className="mt-4 mb-3">{t('allDone')}!</h3>
                          <p className="lead">{t('finishCreatingOrgText')}.</p>
                          <Link className="btn btn-primary" to={`/organization-details/${createdOrganizationId}`}>
                            {t('continue')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabPanels>

                {selectedTabIndex < tabs.length - 1 ? (
                  <div className="d-flex wizard justify-content-between mt-4">
                    <div className="previous me-2">
                      <button className="btn btn-secondary" onClick={prevTabHandler} disabled={selectedTabIndex === 0}>
                        {t('back')}
                      </button>
                    </div>
                    <div className="next">
                      <button
                        className="btn btn-secondary mt-3 mt-md-0"
                        onClick={nextTabHandler}
                        disabled={selectedTabIndex === tabs.length - 1}
                      >
                        {selectedTabIndex < tabs.length - 2 ? t('next') : t('finish')}
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </TabGroup>
        </div>
      </div>
    </div>
  );
};

export default OrganizationCreatePage;
