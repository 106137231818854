import React from 'react';
import { useTranslation } from 'react-i18next';

import { WHITE_LABEL, WHITE_LABEL_NAME, WHITE_LABEL_TITLE } from '../../config/environment';
import { LangDropdown, UserDropdownMenu } from '../../components/common';

interface DasboardPageHeaderProps {
  email?: string;
  onOpen?: (c: boolean) => void;
  isOpen?: boolean;
}

const DasboardPageHeader: React.FC<DasboardPageHeaderProps> = ({ email, onOpen, isOpen }) => {
  const { t } = useTranslation();

  const onClose = () => {
    if (onOpen && isOpen !== undefined) {
      onOpen(!isOpen);
    }
  };

  return (
    <header className="navbar pcoded-header noprint navbar-expand-lg navbar-light">
      <div className="m-header">
        <span className="b-bg ml-4">
          {WHITE_LABEL ? WHITE_LABEL_TITLE : t('dashboard')}
          <br />
          <span style={{ fontSize: +process.env.REACT_APP_FILE_SIZE_LIMIT! / 1024 }}>
            {WHITE_LABEL ? WHITE_LABEL_NAME : 'Loop Campaign'}
          </span>
        </span>
        <a className="mobile-menu" id="mobile-collapse1" href="#">
          <span onClick={onClose}></span>
        </a>
      </div>
      <div className="collapse navbar-collapse limitWidth">
        <ul className="navbar-nav ml-auto nav-title">
          <li>
            <div className="row justify-content-center mr-md-4">
              <LangDropdown />
            </div>
          </li>
          <li>
            <UserDropdownMenu email={email!} />
          </li>
        </ul>
      </div>
    </header>
  );
};

export default DasboardPageHeader;
