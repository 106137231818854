import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { audienceAPIGetAudienceData, audienceAPIGetFilters } from '../../../api/audience';
import { useAuthContext } from '../../../providers/auth/hooks/useAuthContext';
import ErrorPopup from '../../../components/errors/ErrorPopup';
import FilterBlock from './FilterBlock';
import CommonSelect from './CommonSelect';
import MobileMessagesTable from './MobileMessagesTable';
import MessagesTable from './MessagesTable';
import Pagination from './Pagination';
import Spinner from './Spinner';
import Csvonverter from './Csvonverter';

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const t = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(t);
    };
  }, [value, delay]);
  return debouncedValue;
}

interface IProps {
  sendType?: boolean;
  detailsBlock?: boolean;
  onSendMessage?: (sendItems: any) => void;
}

const AudienceContent: FC<IProps> = (props) => {
  const { sendType, onSendMessage, detailsBlock } = props;
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const nPages = 3;

  const [clearFilters, setClearFilters] = useState(false);

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 1000);

  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [entriesValue, setEntriesValue] = useState('20');
  const [isPopup, setIsPopup] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [region, setRegion] = useState('');
  const [type, setType] = useState('');
  const [sortBy, setSortBy] = useState('desc');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);

  const [isMobile] = useState(window.innerWidth < 991);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState({
    page: 1,
    num_pages: 3,
    per_page: 20,
    count: 2,
    items: [
      {
        id: '',
        value: '',
        value_type: '',
        language: '',
        create_date: '',
        carrier: '',
        region: '',
        region_code: '',
      },
    ],
    companies: [
      {
        id: '',
        country: '',
        name: '',
        status: '',
      },
    ],
    types: ['phone', 'email', 'icloud'],
    regions: [
      {
        region: '',
      },
    ],
  });

  const [filterQuery, setFilterQuery] = useState(
    sendType
      ? { page: data.page, per_page: data.per_page, sort_by: 'desc', company: '' }
      : { page: data.page, per_page: data.per_page, sort_by: 'desc' }
  );

  useEffect(() => {
    setClearFilters(false);
  }, []);

  useEffect(() => {
    const arr: any[] = [];
    data.items.map((item: any) => {
      arr.push({ value: item.value, valueType: item.value_type, createDate: item.create_date });
    });
    setCsvData(arr);
  }, [data.items]);

  const filterQueryes = useCallback(
    (query: any) => {
      return Object.assign(filterQuery, query);
    },
    [filterQuery]
  );

  const filtersOnChange = useCallback(() => {
    (async () => {
      try {
        setLoading(true);

        const { data: filteredAudienceResponse } = await audienceAPIGetFilters(filterQuery);

        if (filteredAudienceResponse.code) {
          setIsPopup(true);
          setErrorText(`${filteredAudienceResponse.message}. Error code: ${filteredAudienceResponse.code}`);
        } else if (
          (!filteredAudienceResponse.success &&
            filteredAudienceResponse.status &&
            filteredAudienceResponse.status !== 200) ||
          !filteredAudienceResponse
        ) {
          setIsPopup(true);
          setErrorText(
            filteredAudienceResponse.status
              ? `Something went wrong. Error code: ${filteredAudienceResponse.status}`
              : 'Something went wrong.'
          );
        } else {
          setData(filteredAudienceResponse);
          setLoading(true);
        }
      } catch (e) {
        setError(e as Error);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [error, filterQuery]);

  const deleteQueryes = useCallback(
    (query: any) => {
      for (const key in filterQuery) {
        if (key === query) {
          delete filterQuery[key as keyof typeof filterQuery];
        }
      }
    },
    [filterQuery]
  );

  const onRegion = useCallback(
    (value: string | number) => {
      setRegion(value as string);
      if (value === 'select' || value === '') {
        deleteQueryes('region');
        filtersOnChange();
        setClearFilters(false);
      } else {
        filterQueryes({ region: value });
        filtersOnChange();
        setClearFilters(true);
      }
    },
    [filterQueryes, filtersOnChange, deleteQueryes]
  );

  const onSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    setSearchText(target.value);
  };

  const onCompanyId = useCallback(
    (value: string | number, name: string | undefined) => {
      setCompanyId(value as string);
      setCompanyName(name as string);
      if (name === 'select' || name === '') {
        deleteQueryes('company');
        filtersOnChange();
        setClearFilters(false);
      } else {
        filterQueryes({ company: value });
        filtersOnChange();
        setClearFilters(true);
      }
    },
    [filterQueryes, filtersOnChange, deleteQueryes]
  );

  const onEntries = useCallback(
    (value: string | number) => {
      filterQueryes({ per_page: value });
      filtersOnChange();
      setClearFilters(true);
      setEntriesValue(value as string);
      if (value === '20') {
        setClearFilters(false);
      }
    },
    [filterQueryes, filtersOnChange]
  );

  const onType = useCallback(
    (value: string | number) => {
      setType(value as string);
      if (value === 'select' || value === '') {
        deleteQueryes('type');
        filtersOnChange();
        setClearFilters(false);
      } else {
        filterQueryes({ type: value });
        filtersOnChange();
        setClearFilters(true);
      }
    },
    [filterQueryes, filtersOnChange, deleteQueryes]
  );

  const onSort = useCallback(
    (value: string | number) => {
      setSortBy(value as string);
      filterQueryes({ sort_by: value });
      filtersOnChange();
      setClearFilters(true);
    },
    [filterQueryes, filtersOnChange, setClearFilters]
  );

  const onSearch = useCallback(
    (value: string) => {
      const search = { search: value };
      if (value === '') {
        deleteQueryes('search');
        filtersOnChange();
        setClearFilters(false);
      } else {
        filterQueryes(search);
        filtersOnChange();
        setClearFilters(true);
      }
    },
    [filterQueryes, filtersOnChange, setClearFilters, deleteQueryes]
  );

  useEffect(() => {
    if (debouncedSearch !== '') {
      onSearch(debouncedSearch);
    } else {
      onSearch('');
    }
  }, [debouncedSearch, onSearch]);

  const onCalendar = useCallback(
    (show: boolean) => {
      setShowDatePicker(!show);
      deleteQueryes('from_date');
      deleteQueryes('to_date');
      filtersOnChange();
    },
    [deleteQueryes, filtersOnChange]
  );

  const onClearFilters = useCallback(() => {
    setSearchText('');
    setClearFilters(false);
    setCompanyId(companyId);
    setCompanyName('');
    setFilterQuery({ page: 1, per_page: 20, sort_by: 'desc' });
    setPage(1);
    setEntriesValue('20');
    setSortBy('desc');
    setShowDatePicker(!showDatePicker);
    setType('');
    setRegion('');
    filtersOnChange();
  }, [companyId, filtersOnChange, showDatePicker]);

  const popupClose = useCallback((c: boolean) => {
    setIsPopup(c);
  }, []);

  const onChangeData = useCallback((c: boolean) => {
    if (c) {
      (async () => {
        try {
          const { data: audienceResponse } = await audienceAPIGetAudienceData();

          if (audienceResponse.code) {
            setIsPopup(true);
            setErrorText(`${audienceResponse.message}. Error code: ${audienceResponse.code}`);
          } else if (
            (!audienceResponse.success && audienceResponse.status && audienceResponse.status !== 200) ||
            !audienceResponse
          ) {
            setIsPopup(true);
            setErrorText(
              audienceResponse.status
                ? `Something went wrong. Error code: ${audienceResponse.status}`
                : 'Something went wrong.'
            );
          } else {
            setData(audienceResponse);
          }
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, []);

  const onPage = useCallback(
    (page: number) => {
      setPage(page);
      filterQueryes({ page });
      filtersOnChange();
    },
    [filtersOnChange, filterQueryes]
  );

  return (
    <div className="card-block adaptive-table">
      <FilterBlock
        filterQuery={filterQuery}
        filtersOnChange={filtersOnChange}
        setClearFilters={setClearFilters}
        onEntries={onEntries}
        onSearchText={onSearchText}
        searchText={searchText}
        entriesValue={entriesValue}
        onSort={onSort}
        sortBy={sortBy}
        onCalendar={onCalendar}
        showPicker={showDatePicker}
      />

      <div className={`row ${detailsBlock ? 'justify-content-start' : 'justify-content-between'} row-gap-4 mt-4`}>
        {data.companies.length && !detailsBlock ? (
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="d-flex align-items-center gap-2">
              <p className="text-nowrap mb-0">{t('companyName')}</p>
              <div className="w-100">
                <CommonSelect
                  onChange={onCompanyId}
                  options={data.companies.map((i) => {
                    return { value: i.name, title: i.name };
                  })}
                  id={data.companies.map((i) => {
                    return i.id as string;
                  })}
                  name="companies"
                  startValue={companyName}
                />
              </div>
            </div>
          </div>
        ) : null}
        {data.types.length ? (
          <div className={`col-12 col-sm-6 col-lg-4 ${detailsBlock ? 'm-r-30' : ''}`}>
            <div className="d-flex align-items-center gap-2">
              <p className="mb-0">{t('type')}</p>
              <div className="w-100">
                <CommonSelect
                  onChange={onType}
                  options={data.types.map((i) => {
                    return { value: i, title: i };
                  })}
                  name="type"
                  startValue={type}
                />
              </div>
            </div>
          </div>
        ) : null}
        {data.regions.length ? (
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="d-flex align-items-center gap-2">
              <p className="mb-0">{t('region')}</p>
              <div className="w-100">
                <CommonSelect
                  options={data.regions.map((i) => {
                    return { value: i.region, title: i.region };
                  })}
                  name="region"
                  onChange={onRegion}
                  startValue={region}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div
        className={`row ${clearFilters ? 'justify-content-between' : 'justify-content-end'} align-items-center mt-4`}
      >
        {clearFilters ? (
          <div className="col-auto">
            <button type="button" className="btn btn-sm btn-outline-primary" onClick={onClearFilters}>
              Clear filters
            </button>
          </div>
        ) : null}

        <div className="col-auto">
          <Csvonverter fileName="audience" items={csvData} />
        </div>
      </div>

      {isPopup ? <ErrorPopup isPopup={isPopup} errorCode={errorText} popupClose={popupClose} /> : null}

      {loading ? <Spinner /> : null}

      {data.items.length === 0 ? (
        <p className="text-center">{t('noResults')}</p>
      ) : (
        <>
          {!loading ? (
            isMobile ? (
              <MobileMessagesTable data={data.items} onChange={onChangeData} />
            ) : (
              <MessagesTable
                data={data.items}
                onChange={detailsBlock ? undefined : onChangeData}
                sendType={sendType}
                onSendMessage={onSendMessage}
              />
            )
          ) : null}
          {!loading ? <Pagination total={nPages} page={page} onChange={onPage} /> : null}
        </>
      )}
    </div>
  );
};
export default AudienceContent;
