// import { mergician } from 'mergician';

// export function getUnifiedFormData(formEl: HTMLFormElement) {
//   return Array.from(new FormData(formEl).entries()).reduce((result: any, [key, value]) => {
//     // isObject
//     // if (key.includes('.')) {
//     const nestedKeys = key.match(/\b\w+\b(?=\.?)/g); // convert string some.nested.str ==> ['some', 'nested', 'str'];
//     if (nestedKeys === null) return;

//     const createNestedObject = (keys: string[], value: any): any => {
//       if (keys.length === 0) {
//         return value;
//       }

//       const singleKey = keys.shift()!;
//       const nestedValue = createNestedObject(keys, value);

//       console.log('singleKey', singleKey);
//       console.log('nestedValue', nestedValue);

//       return { [singleKey]: nestedValue };
//     };

//     const nestedObj = createNestedObject(nestedKeys, value);

//     // if (Object.hasOwn(result, key)) {
//     //   if (Array.isArray(result[key])) {
//     //     result[key].push(value);
//     //   } else {
//     //     result[key] = [result[key], value];
//     //   }
//     // } else {
//     //   result[key] = value;
//     // }

//     result = mergician({ appendArrays: true })(result, nestedObj);
//     // }

//     return result;
//   }, {});
// }

export function getUnifiedFormData(formEl: HTMLFormElement) {
  return Array.from(new FormData(formEl).entries()).reduce((result: any, [key, value]) => {
    const nestedKeys = key.match(/\b\w+\b(?=\.?)/g); // convert string some.nested.str ==> ['some', 'nested', 'str'];
    if (nestedKeys === null) return result;

    const createNestedObject = (obj: any, keys: string[], value: any): any => {
      const currentKey = keys[0];

      if (keys.length === 1) {
        if (currentKey in obj) {
          if (Array.isArray(obj[currentKey])) {
            obj[currentKey].push(value);
          } else {
            obj[currentKey] = [obj[currentKey], value];
          }
        } else {
          obj[currentKey] = value;
        }
      } else {
        if (!(currentKey in obj)) {
          obj[currentKey] = {};
        }
        createNestedObject(obj[currentKey], keys.slice(1), value);
      }

      return obj;
    };

    return createNestedObject(result, nestedKeys, value);
  }, {});
}
