import { AxiosResponse } from 'axios';
import { baseApi } from './baseApi';
// import { API_ENDPOINT } from '../config/environment';
// import { errorsHandler } from './utils';

export const getAllAdvertisingModalsByOrganizationAPI = async (organizationId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/sign-up-forms/${organizationId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAdvertisingModalAPI = async (organizationId: string, modalId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.get(`/dashboard/sign-up-form/${organizationId}/${modalId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAdvertisingModalAPI = async (organizationId: string, data: any): Promise<AxiosResponse> => {
  try {
    return await baseApi.post(`/dashboard/sign-up-form/${organizationId}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAdvertisingModalAPI = async (
  organizationId: string,
  modalId: string,
  data: any
): Promise<AxiosResponse> => {
  try {
    return await baseApi.put(`/dashboard/sign-up-form/${organizationId}/${modalId}/`, data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAdvertisingModalAPI = async (organizationId: string, modalId: string): Promise<AxiosResponse> => {
  try {
    return await baseApi.delete(`/dashboard/sign-up-form/${organizationId}/${modalId}/`);
  } catch (error) {
    return Promise.reject(error);
  }
};
