import { FC } from 'react';
import { Portal } from '@headlessui/react';
import * as RadixToast from '@radix-ui/react-toast';

import { useToast } from './useToast';
import { Toast } from './Toast';

export const Toaster: FC = () => {
  const { toasts } = useToast();

  return (
    <RadixToast.Provider duration={5000}>
      {toasts.map(({ id, ...props }) => (
        <Toast key={id} {...props} />
      ))}

      <Portal>
        {/* className="position-fixed bottom-0 end-0 max-vh-100 w-100 p-4 mb-0 list-unstyled" */}
        <RadixToast.Viewport className="toast-viewport" />
      </Portal>
    </RadixToast.Provider>
  );
};
